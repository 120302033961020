import { client } from "data/axios";
import { QuestionnaireDetail } from "types/types";

export async function getQuestionnaireDetail(
  questionnaireId: string
): Promise<QuestionnaireDetail | undefined> {
  try {
    const url = `/questionnaire-detail/${questionnaireId}`;
    const response = await client.get<QuestionnaireDetail>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
