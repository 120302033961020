import { Modal } from "ui/molecules/modal";
import { Text } from "ui/atoms/text";
import "./index.scss";
import { TextButton, textButton } from "ui/molecules/text-button";
import classNames from "classnames";
import { ReactNode } from "react";
import { InternalScroll } from "ui/atoms/internal-scroll";

export const Dialog = ({
  title,
  text,
  isOpen,
  setIsOpen,
  className,
  buttonValue,
  handleClick,
}: {
  title: string;
  text: Array<string | ReactNode>;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  className?: string;
  buttonValue: string;
  handleClick: () => void;
}) => {
  const classes = classNames(["Dialog", className && className]);
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className={classes}
      isDialog={true}
    >
      <Text size="h2" className="dialog-title">
        {title}
      </Text>
      <div className={"dialog-content"}>
        {text.map((data, index) => (
          <span key={index}>
            {index !== 0 && <br />}
            <Text size={"lg"} color={"gray_800"} className="dialog-text">
              {data}
            </Text>
          </span>
        ))}
      </div>
      <div className="button-container">
        <TextButton
          value={buttonValue}
          onClick={handleClick}
          type={textButton.white}
        />
      </div>
    </Modal>
  );
};
