import { useEffect, useState } from "react";

import "./index.scss";

import { QuestionnaireListType } from "types/types";
import { getQuestionnaireList } from "data/api/getQuestionnaireList";
import { useTabContext } from "components/tab-provider";
import { LoadingPage } from "components/loading-page";
import { Wall } from "ui/molecules/wall";
import { QuestionnaireCard } from "ui/molecules/questionnaire-card";

export const QuestionnaireList = () => {
  const { setHeaderContent } = useTabContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [questionnaireList, setQuestionnaireList] = useState<
    Array<QuestionnaireListType>
  >([]);
  const success = (response: Array<QuestionnaireListType>) => {
    setIsError(false);
    setQuestionnaireList(response);
  };
  useEffect(() => {
    document.title = "アンケート一覧";
    setHeaderContent({
      mainHeader: "アンケート一覧",
      subHeader: "これまでに実施されたアンケートを確認します",
    });
    setIsLoading(true);
    (async () => {
      const response = await getQuestionnaireList();
      setIsLoading(false);
      !!response ? success(response) : setIsError(true);
    })();
  }, []);
  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <Wall value="アンケートデータの取得に失敗しました" icon="fail" />
  ) : questionnaireList.length > 0 ? (
    <div className="QuestionnaireList">
      {questionnaireList.map((data, index) => (
        <QuestionnaireCard
          data={data}
          path="/questionnaire-findings/"
          key={index}
        />
      ))}
    </div>
  ) : (
    <Wall value="アンケートはまだ作成されていません" icon="null" />
  );
};
