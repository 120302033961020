import { client } from "data/axios";
import { CheckCarteIdData, CheckCarteIdDataResult } from "types/patientGroupListTypes";
/**
 * postPatientGroupListCarteIdDuplicateCheck
 * 目的：カルテIDが重複するか
 * @param data 
 * @returns response.data
 */
export async function postPatientGroupListCarteIdDuplicateCheck(
  data: CheckCarteIdData
): Promise<CheckCarteIdDataResult | undefined> {
  try {
    const url = "/patient-group-list/carte-id/duplicate-check";
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
