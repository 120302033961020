import { client } from "data/axios";

export async function deleteQuestionnaireDrop(
  id: string
): Promise<boolean | undefined> {
  try {
    const url = `/questionnaire-drop/${id}`;
    const response = await client.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
