import React, { useEffect, useState } from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { TextArea } from "ui/molecules/text-area";
import { QuocardStatusListType } from "types/quocard-status";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { setToast } from "ui/molecules/toast";
import { Modal } from "ui/molecules/modal";
import { ConfirmDialog } from "components/confirm-dialog";
import { putQuoHistoryOverride } from "data/api/putQuoHistoryOverride";
import { LoadingCircle } from "ui/molecules/loading-circle";
import { DropDown } from "ui/molecules/drop-down";
import { LeadButton } from "ui/molecules/lead-button";

export const QuoCardHistoryListRegister = ({
  patientId,
  isOpen,
  setIsOpen,
  quoHistoryData,
  setQuoHistoryData,
}: {
  patientId: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  quoHistoryData: QuocardStatusListType[];
  setQuoHistoryData: (
    value: React.SetStateAction<QuocardStatusListType[]>,
  ) => void;
}) => {
  const [updatedata, setUpdateData] = useState(quoHistoryData);
  const [isLoading, setIsLoading] = useState(false);

  // 再レンダリング処理
  useEffect(() => {
    if (quoHistoryData) {
      setUpdateData(quoHistoryData);
      return;
    }
  });

  // データ変更時の再レンダリング
  useEffect(() => {
    setUpdateData(quoHistoryData);
    return;
  }, [quoHistoryData]);

  // 更新確認ダイアログ
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const handleClick = () => {
    setIsOpenConfirm(true);
  };

  // 更新処理
  const handleUpdate = () => {
    (async () => {
      setIsLoading(true);
      const response = await putQuoHistoryOverride(patientId, updatedata);
      !!response ? successImplement() : failureImplement();
      setIsLoading(false);
    })();
  };

  // 成功・失敗処理
  const successImplement = () => {
    setToast("QUOカード提供履歴を更新しました", true);
  };
  const failureImplement = () => {
    setToast(
      "エラーが発生したためQUOカード提供履歴を更新できませんでした",
      false,
    );
  };

  return (
    <div className="QuoCardHistoryEditor">
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <LoadingCircle />
        ) : (
          <div className="QuoCardEditor">
            <div className="quocard-group-editor-title">
              <Text size={"h3"}>QUOカード提供履歴</Text>
            </div>
              <div className="quo-dialog">
              <table className="quo-table">
                <div className="inline-block_quo">
                  <tbody className="quo-body">
                    {quoHistoryData !== undefined &&
                    quoHistoryData?.length > 0 ? (
                      quoHistoryData.map((data, index) => (
                        <tr className="quo-tr">
                          <QuocardStatusEditor
                            index={index}
                            year={data.quoYear}
                            status={data.quoStatus}
                            setStatus={(value: number) => {
                              setQuoHistoryData(
                                quoHistoryData.map((quoHistory, i) =>
                                  i === index
                                    ? {
                                        quoYear: quoHistory.quoYear,
                                        quoStatus: value,
                                        quoComment: quoHistory.quoComment,
                                      }
                                    : quoHistory,
                                ),
                              );
                            }}
                            comment={data.quoComment}
                            setComment={(value: string) => {
                              setQuoHistoryData(
                                quoHistoryData.map((quoHistory, i) =>
                                  i === index
                                    ? {
                                        quoYear: quoHistory.quoYear,
                                        quoStatus: quoHistory.quoStatus,
                                        quoComment: value,
                                      }
                                    : quoHistory,
                                ),
                              );
                            }}
                            props={(() => {
                              if (data.quoStatus === 4) {
                                return true;
                              } else {
                                return false;
                              }
                            })()}
                          />
                        </tr>
                      ))
                    ) : (
                      <div className="no-answer-data">
                        <Text size="h3" color={"gray_600"}>
                          QUOカード提供履歴が存在しません。
                        </Text>
                      </div>
                    )}
                  </tbody>
                </div>
                </table>
              </div>
              {quoHistoryData !== undefined &&
                      quoHistoryData.length > 0 ? (
                      <LeadButton
                        value="更新"
                        onClick={() => {
                          handleClick();
                        }}
                      />) : ""}
          </div>
        )}
      </Modal>
      <ConfirmDialog
        title={"QUOカード提供履歴の更新を行います"}
        text={[
          "ダイアログに入力された内容でQUOカード提供履歴の更新を行います。",
          <Text size="md" color={"danger"} className="quocard-bundle-alert">
            ⚠️ この操作は取り消せません ⚠️
          </Text>,
        ]}
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        handleClick={async () => {
          !!patientId
            ? handleUpdate()
            : setToast("エラーが発生したため処理が中断されました", false);
        }}
      />
    </div>
  );
};

// エディタ全体
const QuocardStatusEditor = ({
  index,
  year,
  status,
  setStatus,
  comment,
  setComment,
  props,
}: {
  index: number;
  year: number;
  status: number;
  setStatus: (value: number) => void;
  comment: string;
  setComment: (value: string) => void;
  props: boolean;
}) => {
  // 値の更新
  const [value, setValue] = useState(status - 1);
  // コメントの表示・非表示管理
  const [open, setOpen] = useState(props);
  const onClickOpen = (status: number) => {
    if (status === 4) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <td className="quo-td" key={index}>
        <Text size="h3" color={"black"}>
          {year}年度
        </Text>
      </td>
      <td className="quo-td" key={index}>
        <DropDown
          className={"quo-dropdown"}
          value={value}
          options={["未提供", "提供済み", "対象外", "その他"]}
          onChange={(value: number) => {
            setValue(value);
            // valueはプルダウンの配列値になってしまうため+1
            setStatus(value + 1);
            onClickOpen(value + 1);
          }}
        />
      </td>
      <CommentBlockDisp
        index={index}
        comment={comment}
        setComment={setComment}
        props={open}
      />
    </>
  );
};

// コメント表示部分ブロック
const CommentBlockDisp = ({
  index,
  comment,
  setComment,
  props,
}: {
  index: number;
  comment: string;
  setComment: (value: string) => void;
  props: boolean;
}) => {
  // 値の更新
  const [text, setText] = useState(comment);
  // 表示判定
  const open = props;

  return (
    <>
      <td className="quo-td" key={index}>
        {open ? (
          <TextArea
            className={"quo-comment"}
            value={text}
            handle={(value: string) => {
              setText(value);
              setComment(value);
            }}
            placeholder={""}
          />
        ) : (
          <TextArea
            className={"quo-comment-hidden"}
            value={text}
            handle={(value: string) => {
              setText(value);
              setComment(value);
            }}
            placeholder={""}
          />
        )}
      </td>
    </>
  );
};
