import { client } from "data/axios";
import { OverrideValue } from "types/types";

export async function putQuestionnaireValueOverride(
  data: OverrideValue
): Promise<boolean | undefined> {
  try {
    const url = "/questionnaire-value-override";
    const response = await client.put(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
