import { client } from "data/axios";
import { QuocardStatusListType } from "types/quocard-status";
/**
 * API名：getQuoHistoryList
 * 目的：QUOカードの提供状態情報を更新するAPI
 * @param patientId 
 * @returns 患者IDに紐づくQUOカードの提供状態のList
 */
export async function getQuoHistoryList(
  // 患者ID
  patientId: string
): Promise<
  Array<QuocardStatusListType> | undefined
> {
  try {
    const url = `/quocard-state/list/${patientId}`;
    const response = await client.get<Array<QuocardStatusListType>>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
