import { client } from "data/axios";
import { RegisterPatientGroupEditor } from "types/patientGroupListTypes";

export async function postPatientGroupRegistration(
  data: RegisterPatientGroupEditor
): Promise<boolean | undefined> {
  try {
    const url = "/patient-group/registration";
    const response = await client.post(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
