import { client } from "data/axios";
import { QuestionnaireData } from "types/types";

export async function getQuestionnaireRunningData(
  questionnaireId: string
): Promise<QuestionnaireData | undefined> {
  try {
    const url = `/questionnaire-running-data/${questionnaireId}`;
    const response = await client.get<QuestionnaireData>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
