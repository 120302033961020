import "./index.scss";

import { Text } from "ui/atoms/text";
import { Person } from "ui/atoms/person";

export const PersonProfile = ({
  sex,
  carte,
  birth,
  lastQuoYear,
  lastYearQuoStatus,
  thisQuoYear,
  thisYearQuoStatus,
  answeredQuestionnaireCount,
  questionnaireDeliveriesCount,
}: {
  sex: number;
  carte: string;
  birth?: string;
  lastQuoYear?: number;
  lastYearQuoStatus?: number;
  thisQuoYear?: number;
  thisYearQuoStatus?: number;
  answeredQuestionnaireCount?: number;
  questionnaireDeliveriesCount?: number;
}) => {
  let lastYearText = "";
  let thisYearText = "";
  if (lastYearQuoStatus == 0) {
    lastYearText = "-";
  } else if (lastYearQuoStatus == 1) {
    lastYearText = "未提供";
  } else if (lastYearQuoStatus == 2) {
    lastYearText = "提供済み";
  } else if (lastYearQuoStatus == 3) {
    lastYearText = "対象外";
  } else if (lastYearQuoStatus == 4) {
    lastYearText = "その他";
  }
  if (thisYearQuoStatus == 0) {
    thisYearText = "-";
  } else if (thisYearQuoStatus == 1) {
    thisYearText = "未提供";
  } else if (thisYearQuoStatus == 2) {
    thisYearText = "提供済み";
  } else if (thisYearQuoStatus == 3) {
    thisYearText = "対象外";
  } else if (thisYearQuoStatus == 4) {
    thisYearText = "その他";
  }

  return (
    <div className="PersonProfile">
      <div className="profile">
        <div className="icon">
          {sex === 0 && <Person sex="male" />}
          {sex === 1 && <Person sex="female" />}
          {sex !== 0 && sex !== 1 && <Person />}
        </div>
        <div className="carte">
          <Text size="h5">カルテID</Text>
          <Text size="h3">{carte}</Text>
        </div>
        {!!birth && (
          <div className="birth">
            <Text size="h5">生年月日</Text>
            <Text size="h3">{birth}</Text>
          </div>
        )}
        <div className="quo">
          {lastQuoYear != undefined && <Text size="h3">昨年度QUO</Text>}
          {lastYearQuoStatus === undefined && <Text size="h3"></Text>}
          {lastYearQuoStatus === 0 && <Text size="h3">-</Text>}
          {lastYearQuoStatus === 1 && <Text size="h3">未提供</Text>}
          {lastYearQuoStatus === 2 && <Text size="h3">提供済み</Text>}
          {lastYearQuoStatus === 3 && <Text size="h3">対象外</Text>}
          {lastYearQuoStatus === 4 && <Text size="h3">その他</Text>}
        </div>
        <div className="quo">
          {thisQuoYear != undefined && <Text size="h3">今年度QUO</Text>}
          {thisYearQuoStatus === undefined && <Text size="h3"></Text>}
          {thisYearQuoStatus === 0 && <Text size="h3">-</Text>}
          {thisYearQuoStatus === 1 && <Text size="h3">未提供</Text>}
          {thisYearQuoStatus === 2 && <Text size="h3">提供済み</Text>}
          {thisYearQuoStatus === 3 && <Text size="h3">対象外</Text>}
          {thisYearQuoStatus === 4 && <Text size="h3">その他</Text>}
        </div>
        <div className="answer">
          {answeredQuestionnaireCount != undefined && (
            <Text size="h3">回答数</Text>
          )}
          {answeredQuestionnaireCount === undefined && <Text size="h3"></Text>}
          {answeredQuestionnaireCount === 0 && <Text size="h3">-</Text>}
          {answeredQuestionnaireCount != 0 && (
            <Text size="h3">{answeredQuestionnaireCount}</Text>
          )}
        </div>
        <div className="send">
          {questionnaireDeliveriesCount != undefined && (
            <Text size="h3">配信数</Text>
          )}
          {questionnaireDeliveriesCount === undefined && (
            <Text size="h3"></Text>
          )}
          {questionnaireDeliveriesCount === 0 && <Text size="h3">-</Text>}
          {questionnaireDeliveriesCount != 0 && (
            <Text size="h3">{questionnaireDeliveriesCount}</Text>
          )}
        </div>
      </div>
    </div>
  );
};
