import { client } from "data/axios";
import { QuestionnaireUnImplementList } from "types/types";

export async function getQuestionnaireUnImplementList(): Promise<
  Array<QuestionnaireUnImplementList> | undefined
> {
  try {
    const url = `/questionnaire-unimplement-list`;
    const response = await client.get<Array<QuestionnaireUnImplementList>>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
