import { client } from "data/axios";
import { QuestionnaireListType } from "types/types";

export async function getQuestionnaireList(): Promise<
  Array<QuestionnaireListType> | undefined
> {
  try {
    const url = `/questionnaire-list`;
    const response = await client.get<Array<QuestionnaireListType>>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
