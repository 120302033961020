import React, { ReactNode, useContext, useEffect } from "react";
import useLocalStorage from "hooks/useLocalStrage";
import { useNavigate } from "react-router-dom";

const userStorageKey = "__LinqHub_user__V1";

export type User = {
  user: string;
  admin: boolean;
  session: number;
};

type AuthContext = {
  user?: User;
  signIn: (user: string, admin: boolean) => void;
  signOut: () => void;
};

const AuthContext = React.createContext<AuthContext>({
  signOut: () => {
    console.log("signOut unimplemented");
  },
  signIn: () => {
    console.log("signIn unimplemented");
  },
});

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage<User | undefined>(
    userStorageKey,
    undefined,
  );
  const signIn = (user: string, admin: boolean) => {
    const User = {
      user: user,
      admin: admin,
      session: Date.now(),
    };
    setUser(User);
  };
  const signOut = () => {
    setUser(undefined);
  };

  // redirect to signin
  const navigation = useNavigate();
  const redirect = () => {
    // パスが`/signup`以外であった場合ログイン画面へリダイレクト
    if (
      window.location.pathname.indexOf("/signup/") !== 0 &&
      window.location.pathname.indexOf("/line-re-registration-qr") !== 0
    ) {
      navigation("/signin");
    }
  };
  useEffect(() => {
    // ログインしていない場合
    const userUser = user?.user || undefined;
    if (!!!userUser) {
      setUser(undefined);
      redirect();
    }
    // セッション切れの場合
    const untilSessionExpire = 86400000; // １日分のミリ秒 // １日経過するとセッション切れとなる
    const userSession = user?.session || undefined;
    if (!!!userSession || Date.now() - userSession > untilSessionExpire) {
      setUser(undefined);
      redirect();
    }
  }, [user, navigation]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContext => {
  return useContext(AuthContext);
};
