import { client } from "data/axios";
import { QuocardStatusListType } from "types/quocard-status";
/**
 * API名：putQuoHistoryOverride
 * 目的：QUOカードの提供状態情報を更新するAPI
 * @param data 
 * @returns 
 */
export async function putQuoHistoryOverride(
  patientId: string,
  updateData: QuocardStatusListType[]
): Promise<boolean | undefined> {
  try {
    const data = { patientId: patientId, quoInfoList: updateData};
    const url = "/quocard-state/update";
    const response = await client.put(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
