import "./index.scss";

import { Text } from "ui/atoms/text";
import { Container } from "ui/atoms/container";

export const Card = ({
  value,
  head,
  questionnaireStartDate,
  questionnairePatientCount,
  questionnaireAnsweredCount,
  onClick,
}: {
  value: string;
  head?: string;
  questionnaireStartDate: string | undefined;
  questionnairePatientCount: number;
  questionnaireAnsweredCount: number;
  onClick: () => void;
}) => {
  return (
    <Container className="Card" onClick={onClick}>
      <Text size="h5" color="gray_700" className="detail">
        {head}
      </Text>
      <Text size="h1" color="gray_800">
        {value}
      </Text>
      <div>
        <Text>{"アンケート配信開始日：" + questionnaireStartDate}</Text>
      </div>
      <div>
        <Text>
          {"アンケート回答数：" +
            questionnairePatientCount +
            "/" +
            questionnaireAnsweredCount}
        </Text>
      </div>
    </Container>
  );
};
