import React, { useEffect, useState } from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { DropDown } from "ui/molecules/drop-down";
import { TextBox } from "ui/molecules/text-box";
import { Choice, Question } from "types/types";
import {
  textButton,
  TextButton,
  textButtonIcon,
} from "ui/molecules/text-button";
import { circleButton, CircleButton } from "ui/molecules/circle-button";
import { Modal } from "ui/molecules/modal";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { BuildBlock } from "ui/molecules/build-block";
import { setToast } from "ui/molecules/toast";
import { DragAndDropField } from "components/drag-and-drop-field";
import { PatientGroupListType } from "types/patientGroupListTypes";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

const QUESTION_TEXT_MAX_LENGTH = 160; // アンケート本文最大文字数

export const QuestionnaireEditor = ({
  name,
  setName,
  type,
  setType,
  anonymous,
  setAnonymous,
  patientGroupIdList,
  patientGroupNameList,
  patientGroupList,
  selectedPatientGroupName,
  setSelectedPatientGroupName,
  questions,
  setQuestions,
  onClick,
  isNewBuild,
}: {
  name: string;
  setName: (value: string) => void;
  type: number;
  setType: (value: number) => void;
  anonymous: number;
  setAnonymous: (value: number) => void;
  patientGroupIdList: Array<Number>;
  patientGroupNameList: Array<string>;
  patientGroupList: Array<PatientGroupListType>;
  selectedPatientGroupName: Array<string>;
  setSelectedPatientGroupName: (array: Array<string>) => void;
  questions: Array<Question>;
  setQuestions: (array: Array<Question>) => void;
  onClick: () => void;
  isNewBuild: boolean;
}) => {
  const INVISIBLE_CHARACTER = "‎"; // 透明文字（キーボード入力不可）
  let patientGroupNameIdList: Array<string> = [];
  const PATIENT_GROUP_ALL = "全員" + INVISIBLE_CHARACTER + 0;
  // 選択した患者グループの名称をpatientGroupNameIdList配列に追加する処理
  useEffect(() => {
    patientGroupNameList.forEach((patientGroupName, index) => {
      patientGroupNameIdList.push(
        patientGroupName + INVISIBLE_CHARACTER + patientGroupIdList[index],
      );
    });
    // 全員のみの場合、全員を設定する
    if (patientGroupIdList.length == 0) {
      patientGroupNameIdList.push(PATIENT_GROUP_ALL);
      setSelectedPatientGroupName(patientGroupNameIdList);
    } else {
      setSelectedPatientGroupName(patientGroupNameIdList);
    }
  }, []);
  // 質問を追加する
  const handleClickPlus = () => {
    setQuestions([
      ...questions,
      {
        num: 0, // numは最後に設定するので一旦は0で設定する
        text: "",
        property: 0,
        display: 0,
        choices: [
          { text: "", next: questions.length + 2 },
          { text: "", next: questions.length + 2 },
        ],
      },
    ]);
  };

  // 質問を削除する関数
  const handleClickTrash = (numIndex: number) => {
    setQuestions(questions.filter((question, index) => index !== numIndex));
  };

  const handleClickSubmit = async () => {
    if (!!!name || name.trim().length < 1) {
      setToast("アンケート名を入力してください", false, 5000);
    } else if (questions.length < 1) {
      setToast("アンケートの質問をひとつ以上設定してください", false, 5000);
    } else {
      let textFlag = false;
      let choiceFlag = false;
      await questions.forEach((question) => {
        // 質問文の空白のみチェック処理
        if (!!!question.text || question.text.trim().length < 1) {
          textFlag = true;
        }
        if (question.property === 0) {
          // 選択肢の空白のみチェック処理
          question.choices.forEach((choice) => {
            if (!!!choice.text || choice.text.trim().length < 1) {
              choiceFlag = true;
            }
          });
        }
      });

      if (textFlag) {
        setToast("質問文を設定してください", false, 5000);
      } else if (choiceFlag) {
        setToast(
          "選択式の質問の中で、空白の選択肢があります。正しく設定してください。",
          false,
          5000,
        );
      } else {
        onClick();
      }
    }
  };
  // 患者グループを選択するドロップダウンメニュー処理
  const selectPatientGroup = (clickedPatientGroupName: any) => {
    const {
      target: { value },
    } = clickedPatientGroupName;
    setSelectedPatientGroupName(
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // メニュのアイテムの高さ
  const ITEM_HEIGHT = 48;
  // メニュのアイテムの上のパディング
  const ITEM_PADDING_TOP = 8;
  // メニュのアイテムの上の長さ
  const ITEM_WIDTH = 250;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: ITEM_WIDTH,
      },
    },
  };
  return (
    <div className="QuestionnaireEditor">
      <BuildBlock
        title={isNewBuild ? "アンケートの名前" : name}
        exp={
          isNewBuild
            ? "アンケートの名前を設定してください。（患者側の画面には表示されません）"
            : undefined
        }
      >
        {isNewBuild && (
          <TextBox value={name} handle={(name: string) => setName(name)} />
        )}
      </BuildBlock>

      {isNewBuild && (
        <BuildBlock
          title={"問診型/匿名型"}
          exp={"アンケートの形式を選択してください。"}
        >
          <DropDown
            value={anonymous}
            options={["問診型", "匿名型"]}
            onChange={setAnonymous}
          />
        </BuildBlock>
      )}
      <BuildBlock
        title={"患者グループ"}
        exp={"患者グループを選択してください。"}
      >
        <FormControl
          sx={{
            m: 1,
            width: 650,
            backgroundColor: "white",
          }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="patient-group-multiple-checkbox"
            multiple
            value={selectedPatientGroupName}
            onChange={selectPatientGroup}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value.split(INVISIBLE_CHARACTER, 1)}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {/* ユーザーが患者グループを選択すると押した患者グループのチェックが反映される、またpatientGroup配列に追加される */}
            {patientGroupList.map((patientGroup) => (
              <MenuItem
                key={patientGroup.patientGroupName}
                value={
                  patientGroup.patientGroupName +
                  INVISIBLE_CHARACTER +
                  patientGroup.patientGroupId
                }
              >
                <Checkbox
                  checked={
                    selectedPatientGroupName.indexOf(
                      patientGroup.patientGroupName +
                        INVISIBLE_CHARACTER +
                        patientGroup.patientGroupId,
                    ) > -1
                  }
                  disableRipple
                />
                <ListItemText primary={patientGroup.patientGroupName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </BuildBlock>

      <DragAndDropField
        data={questions.map((question, index) => {
          return (
            <QuestionBlock
              index={index}
              question={question}
              questions={questions}
              setQuestions={setQuestions}
              handleClickTrash={() => {
                handleClickTrash(index);
              }}
            />
          );
        })}
        handleDragEnter={{ arr: questions, setArr: setQuestions }}
      />
      <div className="buttons">
        <TextButton
          value="質問を追加する"
          icon={textButtonIcon.plus}
          onClick={handleClickPlus}
          className={"plus-button"}
        />
      </div>
      <TextButton
        value={isNewBuild ? "アンケートを保存する" : "変更を保存する"}
        onClick={handleClickSubmit}
        type={textButton.black}
        className={"build-questionnaire-button"}
        icon={textButtonIcon.save}
      />
    </div>
  );
};

// 質問の追加処理
const QuestionBlock = ({
  index,
  question,
  questions,
  setQuestions,
  handleClickTrash,
}: {
  index: number;
  question: Question;
  questions: Array<Question>;
  setQuestions: (value: Array<Question>) => void;
  handleClickTrash: () => void;
}) => {
  // 質問の編集
  const [isOpenEditor, setIsOpenEditor] = useState(false);

  const handleClickEdit = () => {
    setIsOpenEditor(true);
  };
  // 質問の形式の定数
  // 選択式
  const QUESTION_TYPE_SELECT_INDEX = 0;
  const QUESTION_TYPE_SELECT_TYPE = "選択";

  // 記述
  const QUESTION_TYPE_WRITE_INDEX = 1;
  const QUESTION_TYPE_WRITE_TYPE = "記載";

  // 記述（半角数字)
  const QUESTION_TYPE_WRITE_HALF_WIDTH_INDEX = 2;
  const QUESTION_TYPE_WRITE_HALF_WIDTH_TYPE = "記述(半角数字)";

  // 質問の対象者の定数定義

  // 全員の選択肢
  const QUESTION_PATIENTS_ALL_INDEX = 0;
  const QUESTION_PATIENTS_ALL_TYPE = "全員";

  // 男性のみの選択肢
  const QUESTION_PATIENTS_MALE_INDEX = 1;
  const QUESTION_PATIENTS_MALE_TYPE = "男性のみ";

  // 女性のみの選択肢
  const QUESTION_PATIENTS_FEMALE_INDEX = 2;
  const QUESTION_PATIENTS_FEMALE_TYPE = "女性のみ";

  return (
    <div>
      <BuildBlock title={`質問${index + 1}`} className={"QuestionBlock"}>
        {question.text.length > QUESTION_TEXT_MAX_LENGTH && (
          <Text size={"h4"} color={"danger"}>
            アンケート本文が {question.text.length}文字となっています。
            {QUESTION_TEXT_MAX_LENGTH}文字以内で入力してください。
          </Text>
        )}
        <div>
          <Text color={"gray_700"}>質問文：　</Text>
          {!question.text ? (
            <Text color={"gray_700"}>質問文が設定されていません</Text>
          ) : (
            <Text>{question.text}</Text>
          )}
        </div>
        <div>
          <Text color={"gray_700"}>質問の形式：　</Text>
          <Text>
            {question.property === QUESTION_TYPE_SELECT_INDEX &&
              QUESTION_TYPE_SELECT_TYPE}
            {question.property === QUESTION_TYPE_WRITE_INDEX &&
              QUESTION_TYPE_WRITE_TYPE}
            {question.property === QUESTION_TYPE_WRITE_HALF_WIDTH_INDEX &&
              QUESTION_TYPE_WRITE_HALF_WIDTH_TYPE}
          </Text>
        </div>
        <div>
          <Text color={"gray_700"}>質問の対象者：　</Text>
          <Text>
            {question.display === QUESTION_PATIENTS_ALL_INDEX &&
              QUESTION_PATIENTS_ALL_TYPE}
            {question.display === QUESTION_PATIENTS_MALE_INDEX &&
              QUESTION_PATIENTS_MALE_TYPE}
            {question.display === QUESTION_PATIENTS_FEMALE_INDEX &&
              QUESTION_PATIENTS_FEMALE_TYPE}
          </Text>
        </div>
        <div className="buttons">
          <div className={"circle-button-edit"}>
            <CircleButton type={circleButton.edit} onClick={handleClickEdit} />
          </div>
          <div className={"circle-button-trash"}>
            <CircleButton
              type={circleButton.trash}
              onClick={handleClickTrash}
            />
          </div>
        </div>
      </BuildBlock>
      <QuestionEditor
        index={index}
        text={question.text}
        setText={(value: string) => {
          setQuestions(
            questions.map((question, i) =>
              i === index
                ? {
                    num: question.num,
                    text: value,
                    property: question.property,
                    display: question.display,
                    choices: question.choices,
                  }
                : question,
            ),
          );
        }}
        property={question.property}
        setProperty={(value: number) => {
          setQuestions(
            questions.map((question, i) =>
              i === index
                ? {
                    num: question.num,
                    text: question.text,
                    property: value,
                    display: question.display,
                    choices: question.choices,
                  }
                : question,
            ),
          );
        }}
        display={question.display}
        setDisplay={(value: number) => {
          setQuestions(
            questions.map((question, i) =>
              i === index
                ? {
                    num: question.num,
                    text: question.text,
                    property: question.property,
                    display: value,
                    choices: question.choices,
                  }
                : question,
            ),
          );
        }}
        choices={question.choices}
        setChoices={(value: Array<Choice>) => {
          setQuestions(
            questions.map((question, i) =>
              i === index
                ? {
                    num: question.num,
                    text: question.text,
                    property: question.property,
                    display: question.display,
                    choices: value,
                  }
                : question,
            ),
          );
        }}
        isOpen={isOpenEditor}
        setIsOpen={setIsOpenEditor}
        qLength={questions.length}
      />
    </div>
  );
};

// 質問の編集処理
const QuestionEditor = ({
  index,
  text,
  setText,
  property,
  setProperty,
  display,
  setDisplay,
  choices,
  setChoices,
  isOpen,
  setIsOpen,
  qLength,
}: {
  index: number;
  text: string;
  setText: (value: string) => void;
  property: number;
  setProperty: (value: number) => void;
  display: number;
  setDisplay: (value: number) => void;
  choices: Array<Choice>;
  setChoices: (value: Array<Choice>) => void;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  qLength: number;
}) => {
  const handleClickPlus = () => {
    setChoices([...choices, { text: "", next: index + 2 }]);
  };
  useEffect(() => {
    property === 0 &&
      choices.length === 0 &&
      setChoices([
        {
          text: "",
          next: index + 2,
        },
        {
          text: "",
          next: index + 2,
        },
      ]);
  }, [property]);

  // 選択肢のオプション定数
  const PROPERTY_SETTING_DEFAULT = 0;
  const PROPERTY_SETTING_NEXT_QUESTION = 1;
  const PROPERTY_SETTING_END_QUESTIONNAIRE = 2;
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="QuestionEditor">
        <div className="question-editor-title">
          <Text size={"h3"}>質問{index + 1}</Text>
        </div>
        <InternalScroll height="calc(100% - 25px)">
          <BuildBlock title="質問文" exp="質問文を設定してください。">
            <TextBox
              value={text}
              handle={setText}
              placeholder={"例：喫煙歴はありますか？"}
            />
          </BuildBlock>
          <BuildBlock title="質問の形式" exp="質問の形式を選択してください。">
            <DropDown
              value={property}
              options={["選択式", "記述", "記述(半角数字)"]}
              onChange={(value: number) => {
                setProperty(value);
              }}
            />
          </BuildBlock>
          <BuildBlock
            title="質問の対象者"
            exp="質問の対象者を選択してください。"
          >
            <DropDown
              value={display}
              options={["全員", "男性のみ", "女性のみ"]}
              onChange={setDisplay}
            />
          </BuildBlock>
          {property === PROPERTY_SETTING_DEFAULT && (
            <div>
              {choices.map((choice, i) => (
                <ChoiceBlock
                  key={i}
                  index={i}
                  choice={choice}
                  setChoice={(value: Choice | undefined) => {
                    !!value
                      ? setChoices(
                          choices.map((choice, _i) =>
                            _i === i ? value : choice,
                          ),
                        )
                      : setChoices(choices.filter((_, _i) => _i !== i));
                  }}
                  qIndex={index}
                  qLength={qLength}
                />
              ))}
              {/* 選択肢は最大4つ */}
              {choices.length < 4 && (
                <div className="buttons">
                  <TextButton
                    value="選択肢を追加する"
                    icon={textButtonIcon.plus}
                    onClick={handleClickPlus}
                    className={"plus-button"}
                  />
                </div>
              )}
            </div>
          )}
          {(property === PROPERTY_SETTING_NEXT_QUESTION ||
            property === PROPERTY_SETTING_END_QUESTIONNAIRE) && (
            <BuildBlock
              title="次に進む質問"
              exp="記述問題は次に進む質問を選択することができません。"
            >
              <DropDown
                value={0}
                options={[
                  index + 1 < qLength
                    ? `次の質問(質問${index + 2})に進む`
                    : "アンケートを終了する",
                ]}
                onChange={(_: number) => {}}
                className={"discript-next-selector"}
              />
            </BuildBlock>
          )}
          <TextButton
            value="閉じる"
            icon={textButtonIcon.x}
            onClick={() => {
              setIsOpen(false);
            }}
            className={"choise-close-button"}
          />
        </InternalScroll>
      </div>
    </Modal>
  );
};

// 質問の選択肢処理
const ChoiceBlock = ({
  index,
  choice,
  setChoice,
  qIndex,
  qLength,
}: {
  index: number;
  choice: Choice;
  setChoice: (value: Choice | undefined) => void;
  qIndex: number;
  qLength: number;
}) => {
  const [choiceNext, setChoiceNext] = useState(choice.next - qIndex - 2);
  const nextOptions = [...Array(qLength - qIndex)].map((_, i) =>
    i < 1
      ? `次の質問(質問${i + qIndex + 2})に進む`
      : i < qLength - qIndex - 1
        ? `質問${i + qIndex + 2}に進む`
        : "アンケートを終了する",
  );
  return (
    <BuildBlock
      title={`選択肢${index + 1}`}
      exp={`選択肢${index + 1}について設定してください。`}
      className="ChoiceBlock"
    >
      <TextBox
        value={choice.text}
        handle={(value: string) => {
          setChoice({ text: value, next: choice.next });
        }}
        placeholder={`選択肢${index + 1}を入力してください`}
      />
      {qIndex < qLength - 1 && (
        <DropDown
          value={choiceNext}
          options={nextOptions}
          onChange={(value: number) => {
            setChoiceNext(value);
            setChoice({
              text: choice.text,
              next: value + qIndex + 2,
            });
          }}
          className={"choice-next-selector"}
        />
      )}
      <div className="trash-button">
        <CircleButton
          type={circleButton.trash}
          onClick={() => {
            setChoice(undefined);
          }}
        />
      </div>
    </BuildBlock>
  );
};
