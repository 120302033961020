import { client } from "data/axios";
import { QuestionnaireDetail } from "types/types";

// 本APIでできることは限定される（テーブル questionnaire_{4桁のid}_detail のupdateで対応できる範囲のみ）
// 質問の追加 -> ◯
// 選択肢の追加 -> ◯
// 細かい文言の修正 -> ○
export async function putQuestionnaireOverride(
  data: QuestionnaireDetail
): Promise<boolean | undefined> {
  try {
    const url = "/questionnaire-override";
    const response = await client.put(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
