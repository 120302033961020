import { client } from "data/axios";

type ImplementResultProps = {
  name: string;
  cartes: Array<string>;
};

export async function postQuestionnaireImplement(
  id: number,
  message: string
): Promise<ImplementResultProps | undefined> {
  try {
    const data = {
      id: id,
      message: message,
    };
    const url = "/questionnaire-implement";
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
