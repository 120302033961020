import "./index.scss";

import { Text } from "ui/atoms/text";

export const QuestionnaireTitle = ({
  name,
  type,
  anonymous,
}: {
  name: string;
  type: number;
  anonymous: number;
}) => {
  return (
    <div className="QuestionnaireTitle">
      <div className="types">
        <span>{setType(type)}アンケート</span>
        <span>-</span>
        <span>{setAnonymous(anonymous)}</span>
      </div>
      <Text size="h1" color="gray_800">
        {name}
      </Text>
    </div>
  );
};

export const setType = (i: number) => {
  switch (i) {
    case 0:
      return "登録時";
    case 1:
      return "不定期";
    case 2:
      return "定期";
  }
  return undefined;
};

export const setAnonymous = (i: number) => {
  switch (i) {
    case 0:
      return "問診型";
    case 1:
      return "匿名型";
  }
  return undefined;
};
