import { useEffect, useState } from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { TextForm } from "ui/molecules/text-form";
import { LeadButton } from "ui/molecules/lead-button";
import { Container } from "ui/atoms/container";
import { setToast } from "ui/molecules/toast";
import { useTabContext } from "components/tab-provider";
import { postAccountIssuance } from "data/api/postAccountIssuance";
import { Dialog } from "components/dialog";
import { ToolTip } from "ui/atoms/tooltip";

export const RegistrationDoctor = () => {
  const { setHeaderContent } = useTabContext();
  useEffect(() => {
    document.title = "医師登録";
    setHeaderContent({
      mainHeader: "医師登録",
      subHeader: "",
    });
  }, []);
  const [email, setEmail] = useState("");
  const url = process.env.REACT_APP_URL;
  const [isOpen, setIsOpen] = useState(false);
  const success = () => {
    setToast("アカウントを発行しました。", true, 3000);
    setIsOpen(true);
  };

  const handleClick = async () => {
    if (!!!email) {
      setToast("メールアドレスを入力してください", false);
    } else if (!email.includes("@")) {
      setToast("メールアドレスには'@'が必要です", false);
    } else {
      const result = await postAccountIssuance(email, url ? url : "");
      if (!!result) {
        if (result!.code == 200) {
          success();
        } else if (result!.code == 400) {
          setToast("このメールアドレスは既に使用されています", false);
        } else {
          setToast("アカウント発行に失敗しました", false);
        }
      }
    }
  };

  const [tooltipText, setTooltipText] = useState("copy");
  const copyToClipboard = async (text: string) => {
    await global.navigator.clipboard.writeText(text);
    setTooltipText("コピーしました！");
    setTimeout(() => {
      setTooltipText("copy");
    }, 2000);
  };

  return (
    <>
      <Container className="RegistrationDoctor">
        <Text size="h1">新規アカウント発行</Text>
        <Text size="lg">
          新たに登録する医師のメールアドレスを入力してください
        </Text>
        <TextForm
          type="email"
          value={email}
          placeholder="メールアドレス"
          onChange={setEmail}
        />
        <LeadButton value="アカウントを発行する" onClick={handleClick} />
      </Container>
      <Dialog
        title={"医師アカウントが発行されました"}
        text={[
          <>
            <Text size={"md"} className={"issance-email"}>
              {email}
            </Text>
            宛に送信されたアカウント登録メールを確認してパスワード登録を行なってください。メールが届かない場合は、直接下記のリンクから登録を行なってください。
          </>,
          <ToolTip title={tooltipText}>
            <div
              onClick={() => {
                copyToClipboard(`${url}signup/${email}`);
              }}
              className="clickable-url"
            >
              <Text size="h3" color={"gray_800"}>
                {url}signup/{email}
              </Text>
            </div>
          </ToolTip>,
        ]}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonValue={"閉じる"}
        handleClick={() => {
          setIsOpen(false);
          setEmail("");
        }}
        className={"registration-doctor-dialog"}
      />
    </>
  );
};
