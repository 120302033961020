import { client } from "data/axios";
import { QuestionnaireOverView } from "types/types";

export async function postQuestionnaireBuild(
  data: QuestionnaireOverView
): Promise<boolean | undefined> {
  try {
    const url = "/questionnaire-build";
    const response = await client.post(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
