import "./index.scss";

import { Text } from "ui/atoms/text";
import { LoadingSquares } from "ui/molecules/loading-squares";

export const LoadingPage = ({ comment }: { comment?: string }) => {
  return (
    <div className="LoadingPage">
      <LoadingSquares />
      <div className="comment">
        <Text size="md">{comment}</Text>
      </div>
    </div>
  );
};
