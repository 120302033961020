import "./index.scss";

export const TextCompresser = ({ text }: { text: string }) => {
  const title = text.slice(0, 5);
  return (
    <div className="TextCompresser">
      {title}
      {text.length > 5 && "..."}
      {text.length > 5 && <div className="full-text">{text}</div>}
    </div>
  );
};
