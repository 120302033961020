import { useEffect, useState } from "react";

import "./index.scss";

import { Input } from "ui/atoms/input";
import { Search } from "react-feather";

export const TextBox = ({
  value,
  className,
  isNum = false,
  isSearchBox = false,
  placeholder,
  handle,
}: {
  value: string;
  className?: string;
  isNum?: boolean;
  isSearchBox?: boolean;
  placeholder?: string;
  handle: (option: string) => void;
}) => {
  const [classes, setClasses] = useState<Array<string>>(
    !isSearchBox
      ? className
        ? ["TextBox", className]
        : ["TextBox"]
      : className
        ? ["TextBox", className, "search-box"]
        : ["TextBox", "search-box"],
  );

  const initialValue = value;

  useEffect(() => {
    !initialValue || !!value
      ? setClasses([...classes])
      : setClasses([...classes, "un-match"]);
  }, [value]);

  return (
    <div className="text-box-container">
      <Input
        className={classes}
        type={!isNum ? "text" : "number"}
        value={value}
        placeholder={
          placeholder
            ? placeholder
            : !isNum
              ? "入力してください"
              : "入力してください（半角数字）"
        }
        onChange={handle}
        onKeyDown={() => handle(value)}
      />
      {isSearchBox && <Search size={18} className="text-box-icon" />}
    </div>
  );
};
