import classNames from "classnames";

import "./index.scss";

export const TextAreaForPatientIdList = ({
  className,
  value,
  handle,
  cols,
  rows = 3,
  placeholder,
}: {
  className?: string;
  value: string;
  handle: (value: string) => void;
  cols?: number;
  rows?: number;
  placeholder?: string;
}) => {
  const classes = classNames("TextAreaNumber", className && className);
  return (
    <div className={classes}>
      <textarea
        value={value}
        onChange={(event) =>
          handle(event.target.value.replace(/[^\.0-9^\.\.A-Z^\.\n^\/]/g, ""))
        }
        onKeyDown={(event) => {
          event.key === "Enter" && console.log();
        }}
        placeholder={placeholder}
        rows={rows}
      />
    </div>
  );
};
