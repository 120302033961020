import "./index.scss";

import { Text } from "ui/atoms/text";

export const Note = ({ value, isNote }: { value: string; isNote: boolean }) => {
  return (
    <div className="Note">
      {isNote ? <Text size="sm">{value}</Text> : <></>}
    </div>
  );
};
