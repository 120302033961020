import { client } from "data/axios";
import { CheckPatientGroupData, CheckPatientGroupDataResult } from "types/patientGroupListTypes";

/**
 * API名：patientGroupCheck
 * 目的：患者グループ名が重複してるか、カルテIDが存在するか
 * @param data 
 * @returns 
 */
export async function postPatientGroupCheck(
  data: CheckPatientGroupData
): Promise<CheckPatientGroupDataResult | undefined> {
  try {
    const url = `/patient-group/check`;
    const response = await client.post(url,data);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
