import { Modal } from "ui/molecules/modal";
import { Text } from "ui/atoms/text";
import "./index.scss";
import { TextButton, textButton } from "ui/molecules/text-button";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { LoadingCircle } from "ui/molecules/loading-circle";

export type ConfirmDialogType = {
  title: string;
  text: Array<string | ReactNode>;
  className?: string;
  handleClick: () => void;
};

export const ConfirmDialog = ({
  title,
  text,
  isOpen,
  setIsOpen,
  className,
  handleClick,
}: {
  title: string;
  text: Array<string | ReactNode>;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  className?: string;
  handleClick: () => void;
}) => {
  const classes = classNames(["ConfirmDialog", className && className]);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={!isLoading ? setIsOpen : () => {}}
      className={classes}
      isDialog={true}
    >
      {!isLoading ? (
        <>
          <Text size="h2" className="dialog-title">
            {title}
          </Text>
          <div className={"dialog-content"}>
            {text.map((data, index) => (
              <span key={index}>
                {index !== 0 && <br />}
                <Text size={"lg"} color={"gray_700"} className="dialog-text">
                  {data}
                </Text>
              </span>
            ))}
          </div>
          <div className="button-container">
            <TextButton
              value="いいえ"
              onClick={() => {
                setIsOpen(false);
              }}
              type={textButton.white}
            />
            <TextButton
              value="はい"
              onClick={async () => {
                setIsLoading(true);
                await handleClick();
                setIsOpen(false);
                setIsLoading(false);
              }}
              type={textButton.black}
            />
          </div>
        </>
      ) : (
        <LoadingCircle />
      )}
    </Modal>
  );
};
