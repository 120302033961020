import { client } from "data/axios";
import { CarteIdListType } from "types/patientGroupListTypes";
/**
 * API名：getCarteIdList
 * 目的：カルテIDを取得するAPI
 * @param patientGroupId 
 * @returns カルテIDのList
 */
export async function getCarteIdList(
    patientGroupId: number
): Promise<CarteIdListType | undefined> {
  try {
    
    const url = `/carte-id/list/${patientGroupId}`;
    const response = await client.get<CarteIdListType>(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
