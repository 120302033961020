import { client } from "data/axios";
import { PatientGroupListType } from "types/patientGroupListTypes";

export async function getPatientGroupList(): Promise<
  Array<PatientGroupListType> | undefined
> {
  try {
    const url = `/patient-group/list`;
    const response = await client.get<Array<PatientGroupListType>>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
