import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { Question, QuestionnaireDetail } from "types/types";
import {
  CheckCarteIdData,
  PatientGroupListType,
} from "types/patientGroupListTypes";
import { Text } from "ui/atoms/text";
import { QuestionnaireEditor } from "components/questionnaire-editor";
import { useTabContext } from "components/tab-provider";
import { LoadingPage } from "components/loading-page";
import { Wall } from "ui/molecules/wall";
import { setToast } from "ui/molecules/toast";
import { iconButton, IconButton } from "ui/molecules/icon-button";
import { getQuestionnaireDetail } from "data/api/getQuestionnaireDetail";
import { useConfirmContext } from "components/confirm-dialog-provider";
import { putQuestionnaireOverride } from "data/api/putQuestionnaireOverride";
import { getPatientGroupList } from "data/api/getPatientGroupList";
import React from "react";
import { postPatientGroupListCarteIdDuplicateCheck } from "data/api/postPatientGroupListCarteIdDuplicateCheck";

let questionTextLengthResult = false; // アンケート本文の文字数判定
const QUESTION_TEXT_MAX_LENGTH = 160; // アンケート本文最大文字数
const INVISIBLE_CHARACTER = "‎"; // 透明文字（キーボード入力不可）
export const QuestionnaireBundleEdit = () => {
  const params = useParams();
  const { setHeaderContent } = useTabContext();
  const [questionnaireDetail, setQuestionnaireDetail] =
    useState<QuestionnaireDetail>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigation = useNavigate();

  // エディター
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [anonymous, setAnonymous] = useState(0);
  const [patientGroupId, setPatientGroupId] = useState<Array<number>>([]);
  const [patientGroupName, setPatientGroupName] = useState<Array<string>>([]);
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [patientGroupList, setPatientGroupList] = useState<
    Array<PatientGroupListType>
  >([]);

  const [selectedPatientGroupName, setSelectedPatientGroupName] =
    React.useState<string[]>([]);
  let patientGroupIdList: number[] = [];
  let patientGroupNameList: string[] = [];
  // 重複チェック結果: 重複
  const IS_DUPLICATE = "IS_DUPLICATE";
  // アンケート内容データの取得
  useEffect(() => {
    document.title = "アンケート管理";
    setHeaderContent({
      mainHeader: "アンケート管理",
      subHeader: "アンケートの情報・設定の編集を行います",
    });

    (async () => {
      setIsLoading(true);
      const response = await getQuestionnaireDetail(params.id ? params.id : "");
      setQuestionnaireDetail(response);
      if (!!response) {
        setName(response.name);
        setType(response.type);
        setPatientGroupId(response.patientGroupId);
        setPatientGroupName(response.patientGroupName);
        setAnonymous(response.anonymous);
        setQuestions(response.questions);
      }
      setIsLoading(false);
    })();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const response = await getPatientGroupList();
      setIsLoading(false);
      !!response ? getSuccess(response) : setIsError(true);
    })();
  }, []);

  // アンケート内容を編集
  useEffect(() => {
    !!questionnaireDetail &&
      setQuestionnaireDetail({
        id: questionnaireDetail?.id,
        name: name,
        type: type,
        anonymous: anonymous,
        patientGroupId: patientGroupId,
        patientGroupName: patientGroupName,
        questions: questions,
      });
  }, [name, type, anonymous, patientGroupId, patientGroupName, questions]);

  const handleClick = async () => {
    setIsLoading(true);

    // アンケート本文入力文字数チェック
    questions.map((question) => {
      if (question.text.length > QUESTION_TEXT_MAX_LENGTH) {
        questionTextLengthResult = true;
      }
    });

    if (questionTextLengthResult) {
      setToast(
        "アンケート本文の文字数制限を超えている質問文があります。",
        false,
        2000,
      );
      questionTextLengthResult = false;
      setIsLoading(false);
      return;
    }

    // 項目「患者グループ」の入力チェック
    for (let i = 0; i < selectedPatientGroupName.length; i++) {
      patientGroupIdList.push(
        Number(selectedPatientGroupName[i].split(INVISIBLE_CHARACTER).pop()),
      );
      patientGroupNameList.push(
        selectedPatientGroupName[i].split(INVISIBLE_CHARACTER, 1).toString(),
      );
    }

    if (patientGroupIdList.length == 0) {
      setToast("患者グループを選択してください。", false);
      setIsLoading(false);
      setSelectedPatientGroupName(selectedPatientGroupName);
      patientGroupIdList = [];
      patientGroupNameList = [];
    } else if (
      patientGroupIdList.length == 1 &&
      patientGroupIdList.includes(0)
    ) {
      questionnaireUpdate();
    } else if (
      patientGroupIdList.length > 1 &&
      patientGroupIdList.includes(0)
    ) {
      setToast(
        "全員に配信する場合、患者グループを指定しないでください。",
        false,
        2000,
      );
      setIsLoading(false);
      setSelectedPatientGroupName(selectedPatientGroupName);
      patientGroupIdList = [];
      patientGroupNameList = [];
    } else {
      const checkData: CheckCarteIdData = {
        patientGroupIdList: patientGroupIdList,
      };
      const checkResult =
        await postPatientGroupListCarteIdDuplicateCheck(checkData);
      let carteIdDuplicateInfo = "";
      if (checkResult?.carteIdDuplicateResult == IS_DUPLICATE) {
        carteIdDuplicateInfo = `患者グループが複数指定されていることにより、\n以下のカルテIDが重複しています。\n${checkResult?.carteId}`;
        setConfirmDialog({
          title: "更新します。よろしいですか？",
          text: [carteIdDuplicateInfo],
          handleClick: async () => {
            questionnaireUpdate();
          },
        });
        setIsLoading(false);
        setIsOpen(true);
      } else {
        questionnaireUpdate();
      }
    }
  };
  const questionnaireUpdate = async () => {
    if (!!questionnaireDetail) {
      setIsLoading(true);
      const data: QuestionnaireDetail = {
        id: questionnaireDetail.id,
        name: questionnaireDetail.name,
        type: questionnaireDetail.type,
        anonymous: questionnaireDetail.anonymous,
        patientGroupId: patientGroupIdList,
        patientGroupName: patientGroupNameList,
        questions: questionnaireDetail.questions.map((question, index) => ({
          num: index + 1,
          text: question.text,
          property: question.property,
          display: question.display,
          choices: question.property === 0 ? question.choices : [],
        })),
      };
      const result = await putQuestionnaireOverride(data);
      !!result ? success() : setToast("アンケートの保存に失敗しました", false);
      setIsLoading(false);
    }
  };
  // 初回表示用患者グループ（空要素）
  const defaultData: PatientGroupListType = {
    patientGroupId: 0,
    patientGroupName: "全員",
    patientCount: 0,
    patientGroupInformation: "",
    dataBuild: new Date(),
    dataUpdate: new Date(),
    flag: 0,
  };
  // 患者グループ一覧の取得
  const getSuccess = (data: Array<PatientGroupListType>) => {
    data.unshift(defaultData); // 患者グループの配列0番目に空要素を追加
    setPatientGroupList(data);
  };

  const success = () => {
    setToast("アンケートを更新しました", true);
    navigation("/questionnaire-bundle");
  };

  // backボタン
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  const handleClickBack = () => {
    setConfirmDialog({
      title: "ページを移動してよろしいですか？",
      text: ["編集した内容は保存されません"],
      handleClick: () => {
        navigation("/questionnaire-bundle");
      },
    });
    setIsOpen(true);
  };
  return isLoading ? (
    <LoadingPage />
  ) : !!questionnaireDetail || isError ? (
    <div className="QuestionnaireBundleEdit">
      <IconButton
        value={iconButton.arrowLeft}
        onClick={handleClickBack}
        className={"back-button"}
      />
      <Text size="h5" color="gray_600" className="exp">
        アンケートの項目を編集してください
      </Text>
      <QuestionnaireEditor
        name={name}
        setName={setName}
        type={type}
        setType={setType}
        anonymous={anonymous}
        setAnonymous={setAnonymous}
        patientGroupIdList={patientGroupId}
        patientGroupNameList={patientGroupName}
        patientGroupList={patientGroupList}
        selectedPatientGroupName={selectedPatientGroupName}
        setSelectedPatientGroupName={setSelectedPatientGroupName}
        questions={questions}
        setQuestions={setQuestions}
        onClick={() => {
          handleClick();
        }}
        isNewBuild={false}
      />
    </div>
  ) : (
    <Wall value="アンケートデータの取得に失敗しました" icon="fail" />
  );
};
