import React from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { TextBox } from "ui/molecules/text-box";
import { RegisterPatientGroupEditor } from "types/patientGroupListTypes";
import {
  TextButton,
  textButton,
  textButtonIcon,
} from "ui/molecules/text-button";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { BuildBlock } from "ui/molecules/build-block";
import { setToast } from "ui/molecules/toast";
import { TextArea } from "ui/molecules/text-area";
import { Modal } from "ui/molecules/modal";
import { postPatientGroupRegistration } from "data/api/postPatientGroupRegistration";
import { TextAreaForPatientIdList } from "ui/molecules/text-area-for-patient-id-list";
import { useSecondConfirmContext } from "components/second-confirm-dialog-provider";
import { PatientGroupChecker } from "components/common/patient-group-checker";

export const PatientGroupListRegister = ({
  patientGroupName,
  setPatientGroupName,
  patientNote,
  setPatientNote,
  carteIdList,
  setCarteIdList,
  isOpen,
  setIsOpen,
}: {
  patientGroupName: string;
  setPatientGroupName: (value: string) => void;
  patientNote: string;
  setPatientNote: (value: string) => void;
  carteIdList: string;
  setCarteIdList: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  // 重複ダイアログ
  const { innerSetConfirmDialog, innerSetIsOpen } = useSecondConfirmContext();
  // 備考欄の最大行数
  const PATIENT_LIST_INFO_MAX_ROWS = 3;
  const PATIENT_LIST_INFO_MAX_COLUMNS = 40;
  // 患者グループ対象者の最大行数
  const PATIENT_LIST_TEXT_MAX_ROWS = 18;
  const PATIENT_LIST_TEXT_MAX_COLUMNS = 40;
  // 患者グループ名の文字数上限
  const PATIENT_GROUP_NAME_LIMIT = 100;
  // 備考欄の文字数上限
  const PATIENT_GROUP_NOTE_LIMIT = 500;
  // 問題なし結果：
  const IS_NOT_DUPLICATE = "";
  // 文字数０桁の定数
  const STRING_LENGTH_ZERO = 0;
  // 患者グループ登録の「作成する」ボタンを押下する処理
  const handleUpdate = async () => {
    if (!!!patientGroupName) {
      setToast("患者グループ名を入力してください。", false, 5000);
      return;
    }
    if(patientGroupName.length > PATIENT_GROUP_NAME_LIMIT){
      setToast("グループ名は100文字以内で入力してください。", false, 5000);
      return;
    }
    if(patientNote.length > PATIENT_GROUP_NOTE_LIMIT){
      setToast("備考欄は500文字以内で入力してください。", false, 5000);
      return;
    }
    // 患者グループ対象者に入力がある場合
    if(carteIdList.length > STRING_LENGTH_ZERO){
      // 患者グループ対象者の入力値を改行("\n")で分けて、文字数を確認する処理
      let carteIdListEmptyChecker = carteIdList.split("\n",)
      // 複数の空改行のチェック処理
      for (let carteId of carteIdListEmptyChecker) {
        if(carteId.length == STRING_LENGTH_ZERO){
          setToast("患者グループ対象者は空の改行が入っています。空の改行を消してください。", false, 5000);
          return;
        }
      }
    }
    
    // 入力したカルテIDが重複するかチェックする処理
    const response = await PatientGroupChecker(patientGroupName, carteIdList);
    if (!response) {
      return;
    }
    // 患者グループ名が重複した場合、setToast
    if (response?.duplicateGroupName == patientGroupName) {
      // グループ名が重複する
      setToast(
        "患者グループ名が重複しています。もう一度入力してください。",
        false,
        5000,
      );
      return;
    }
    // 患者グループ名が重複しない・カルテIDの存在・重複チェック正常結果
    if (
      response?.duplicateInfo == IS_NOT_DUPLICATE &&
      response?.carteIdNotExistInfo == IS_NOT_DUPLICATE
    ) {
      patientGroupListRegister();
    } else {
      innerSetConfirmDialog({
        title: "",
        text: [response?.duplicateInfo],
        secondText: [],
        thirdText: [response?.carteIdNotExistInfo],
        fourthText: [],
        handleClick: async () => {
          patientGroupListRegister();
        },
      });
      innerSetIsOpen(true);
    }
  };

  // 患者グループ登録処理
  const patientGroupListRegister = async () => {
    const data: RegisterPatientGroupEditor = {
      patientGroupName: patientGroupName,
      patientGroupInformation: patientNote,
      carteIdList: carteIdList.replaceAll("\n", ","),
    };
    const result = await postPatientGroupRegistration(data);
    !!result ? success() : setToast("グループの登録に失敗しました", false);
  };

  const success = () => {
    setToast("グループを登録しました", true, 6000);
    setTimeout(() => {
      window.location.reload();
      setIsOpen(false);
    }, 1000);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="PatientGroupEditor">
        <div className="PatientRegisterDialogue">
          <InternalScroll className="patient-editor-title">
            <Text size={"h3"}>患者グループ登録</Text>
          <div className="dialog-content">
            <BuildBlock title="グループ名">
              <TextBox
                className="patient-list-group-name"
                value={patientGroupName}
                handle={setPatientGroupName}
                placeholder={"患者グループ名"}
              />
            </BuildBlock>
            <BuildBlock title="備考欄">
              <TextArea
                className="patient-list-note"
                value={patientNote}
                handle={setPatientNote}
                placeholder={"備考の情報を入力してください"}
                rows={PATIENT_LIST_INFO_MAX_ROWS}
                cols={PATIENT_LIST_INFO_MAX_COLUMNS}
              />
            </BuildBlock>
            <BuildBlock
              title="患者グループ対象者"
              exp="カルテIDを編集してください。"
            >
              <TextAreaForPatientIdList
                className="patient-list-text-box"
                value={carteIdList}
                handle={setCarteIdList}
                placeholder={
                  "カルテIDを入力してください。 \n例:\n00000001\n00000002\n00000003"
                }
                rows={PATIENT_LIST_TEXT_MAX_ROWS}
                cols={PATIENT_LIST_TEXT_MAX_COLUMNS}
              />
            </BuildBlock>
            <TextButton
              value="作成する"
              icon={textButtonIcon.save}
              onClick={() => {
                handleUpdate();
              }}
              className={"editor-register-button"}
              type={textButton.black}
            />
          </div>
          </InternalScroll>
        </div>
      </div>
    </Modal>
  );
};
