import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { AuthContextProvider } from "components/auth-provider";
import { TabContextProvider } from "components/tab-provider";
import { RouteSelector } from "route";
import "the-new-css-reset/css/reset.css";
import { ConfirmContextProvider } from "components/confirm-dialog-provider";
import { version } from "version";
import { SecondConfirmContextProvider } from "components/second-confirm-dialog-provider";

const App = () => {
  console.log(`version: ${version}`);
  return (
    <Router>
      <AuthContextProvider>
        <ConfirmContextProvider>
          <SecondConfirmContextProvider>
          <TabContextProvider>
            <Toaster />
            <RouteSelector />
          </TabContextProvider>
          </SecondConfirmContextProvider>
        </ConfirmContextProvider>
      </AuthContextProvider>
    </Router>
  );
};

export default App;
