import { client } from "data/axios";

export async function postSendLineDirectMessage(
  receiverIds: Array<string>,
  message: string
): Promise<Array<string> | undefined> {
  const data = { receiver: receiverIds, message: message };
  try {
    const url = "/line-direct-message";
    const response = await client.post(url, data);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
