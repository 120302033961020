import "./index.scss";
import { Text } from "ui/atoms/text";
import { useEffect, useState } from "react";
import { LoadingPage } from "components/loading-page";
import { useLocation } from "react-router-dom";
import { getLineReRegistrationQR } from "data/api/getLineReRegistrationQR";

export const LineReRegistrationQR = () => {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const code = params.get("code");
  const redirectUri = params.get("liffRedirectUri");
  const [isLoading, setIsLoading] = useState(false);
  const [resultText, setResultText] = useState<string>();
  useEffect(() => {
    document.title = "LINE再登録｜東北大学病院LINEアンケート";
    (async () => {
      setIsLoading(true);
      const response = await getLineReRegistrationQR(
        id ? id : "",
        code ? code : "",
        redirectUri ? redirectUri : "",
      );
      !!response
        ? setResultText("LINE ID登録が正常に行われました")
        : setResultText("エラー：LINE ID登録が正常に行われませんでした");
      setIsLoading(false);
    })();
  }, []);
  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="LineReRegistration">
      <Text size="h2" color="gray_800" className="signin__title">
        {resultText}
      </Text>
    </div>
  );
};
