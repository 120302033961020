import { client } from "data/axios";

type ImplementResultProps = {
  name: string;
  cartes: Array<string>;
};
/**
 * API名：questionnaireImplementForPatientGroup
 * 目的：グループIDが持ってる場合、特定な患者IDに配信すること。
 * @param patientGroupId 
 * @param id 
 * @param message 
 * @returns 
 */
export async function postQuestionnaireImplementForPatientGroup(
  patientGroupIdList: Array<number>,
  id: number,
  message: string
): Promise<ImplementResultProps | undefined> {
  try {
    const data = {
      patientGroupIdList: patientGroupIdList,
      id: id,
      message: message,
    };
    const url = "/patient-group/questionnaire/implement";
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
