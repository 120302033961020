import { client } from "data/axios";
import { QuestionnaireNameData, QuestionnaireNameDataResult } from "types/types";

/**
 * API名：questionnaireNameDuplicateCheck
 * 目的：アンケート名の重複チェック
 * @param checkName 
 * @returns 
 */


export async function postQuestionnaireNameDuplicateCheck(
  checkName: QuestionnaireNameData
): Promise<QuestionnaireNameDataResult | undefined> {
  try {
    const url = `/questionnaire-name-duplicate-check`;
    const response = await client.post(url,checkName);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
