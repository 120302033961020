import { client } from "data/axios";
import { NewQuocardStatusType } from "types/quocard-status";
/**
 * API名：postQuoHistoryYearOverride
 * 目的：QUOカードの現在年度を登録するAPI
 * @param data 
 * @returns 
 */
export async function postQuoHistoryYearOverride(
  data: NewQuocardStatusType
): Promise<boolean | undefined> {
  try {
    const url = "/quocard-year/registration";
    const response = await client.post(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
