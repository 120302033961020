import { ParticipantData } from "pages/participant-data";
import { Modal } from "ui/molecules/modal";
import QRCode from "qrcode.react";
import { Text } from "ui/atoms/text";
import { useEffect, useState } from "react";
import "./index.scss";
import { TextButton } from "ui/molecules/text-button";
import { useLayoutContext } from "components/layout";
import { useTabContext } from "components/tab-provider";
import { Dialog } from "components/dialog";
import { useNavigate, useParams } from "react-router-dom";
import { postGenerateLineToken } from "data/api/postGenerateLineToken";
import { setToast } from "ui/molecules/toast";
import { LoadingCircle } from "ui/molecules/loading-circle";

export const ParticipantDataLineRegistration = () => {
  const param = useParams();
  const layout = useLayoutContext();
  const { setHeaderContent } = useTabContext();
  const navigation = useNavigate();

  // LINE再登録（QRコード）エンドポイント
  const [isOpenDialog, setIsOpenDialog] = useState(true);
  const [isOpenLineRegiQR, setIsOpenLineRegiQR] = useState(false);
  const [token, setToken] = useState<string>();
  const [URL, setURL] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    layout.setSidebar(false);
    document.title = "⚠️ LINE再登録 ⚠️";
    setHeaderContent({
      mainHeader: "LINE再登録",
      subHeader: "LINEの再登録を行います",
    });
    GenerateURL(param.id ? param.id : "");
    setIsOpenDialog(false);
  };
  const GenerateURL = async (carte: string) => {
    setIsLoading(true);
    const result = await postGenerateLineToken(carte);
    !!result && result.carte === carte
      ? // !!result
        success(result.id, result.tokenCode, result.sysURL, result.clientId)
      : setToast(
          "エラーが発生しました\nLINE再登録トークンの生成中のエラーもしくは既にLINE再登録が完了している可能性があります",
          false,
        );
    setIsLoading(false);
  };
  const success = (
    id: string,
    token: string,
    sysURL: string,
    clientId: string,
  ) => {
    // const encodeUrl = encodeURIComponent(sysURL);
    const encodeUrl = encodeURIComponent(
      process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "",
    );
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeUrl}line-re-registration-qr%3Fid%3D${id}&bot_prompt=aggressive&state=123&scope=profile`;
    console.log(url);
    setURL(url);
    setToken(token);
    setIsOpenLineRegiQR(true);
  };

  // QRコードを閉じた際に前ページに移動してリロードを行う（LINE再登録が完了したことを想定）
  const reloadParticipant = () => {
    navigation("/participant");
    layout.setSidebar(true);
  };
  useEffect(() => {
    !isOpenLineRegiQR && !isOpenDialog && reloadParticipant();
  }, [isOpenLineRegiQR]);

  return (
    <>
      <ParticipantData />
      <TextButton
        value="LINE再登録"
        onClick={() => setIsOpenDialog(true)}
        className={"qr-line-registration-button"}
      />
      <Dialog
        title={"⚠️ LINE再登録を行います ⚠️"}
        text={[
          "医師は次に表示されるQRコードを患者さんに提示してください。",
          "患者さんのQRコード読み取りが完了したらLINEの再登録は終了です。",
        ]}
        isOpen={isOpenDialog}
        setIsOpen={setIsOpenDialog}
        buttonValue={"QRコードを生成する"}
        handleClick={handleClick}
      />
      <Modal isOpen={isLoading} setIsOpen={() => {}}>
        <LoadingCircle />
      </Modal>
      {!!URL && !!token && (
        <LineRegistrationModal
          qrText={URL}
          token={token}
          isOpen={isOpenLineRegiQR}
          setIsOpen={setIsOpenLineRegiQR}
        />
      )}
    </>
  );
};

const LineRegistrationModal = ({
  qrText,
  token,
  isOpen,
  setIsOpen,
}: {
  qrText: string;
  token: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [mode, setMode] = useState<"qr" | "dm">("qr");
  return (
    <Modal
      className="LineRegistrationModal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {mode === "qr" ? (
        <>
          <QRCode value={qrText} renderAs="svg" className={"qr-code"} />
          <Text size="h1" color={"black"} className={"text"}>
            QRコードをお手持ちのスマートフォンで読み取って下さい。
          </Text>
          <TextButton
            value="QRコード読み込みがうまくいかない場合"
            onClick={() => {
              setMode("dm");
            }}
            className={"line-auth-button"}
          />
        </>
      ) : (
        <>
          <div className="token">{token}</div>
          <Text size="h1" color={"black"} className={"token-text"}>
            表示されている数字4桁の認証コードをLINEトーク上で直接打って送信してください
          </Text>
          <TextButton
            value="QRコードでのLINE登録を行う"
            onClick={() => {
              setMode("qr");
            }}
            className={"line-auth-button"}
          />
        </>
      )}
    </Modal>
  );
};
