import React, { useEffect, useState } from "react";

import "./index.scss";

import { TableHead, TableBody, OverrideValue } from "types/types";
import { putQuestionnaireValueOverride } from "data/api/putQuestionnaireValueOverride";
import { Modal } from "ui/molecules/modal";
import { setToast } from "ui/molecules/toast";
import { DropDown } from "ui/molecules/drop-down";
import { LeadButton } from "ui/molecules/lead-button";
import { PersonProfile } from "ui/molecules/person-profile";
import { TextBox } from "ui/molecules/text-box";
import { TextCompresser } from "ui/atoms/text-compresser";

export const AnswerEditor = ({
  questionnaireId,
  tableHead,
  tableBody,
  isOpen,
  setIsOpen,
  handleReload,
}: {
  questionnaireId: number;
  tableHead: Array<TableHead>;
  tableBody: TableBody;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  handleReload: () => void;
}) => {
  // データの更新
  const [newAnswerData, setNewAnswerData] = useState<
    Array<string | number | null | undefined>
  >(tableBody.answer);
  useEffect(() => {
    setNewAnswerData(!isOpen ? [] : tableBody.answer);
  }, [isOpen]);
  const updateAnswerData = async (
    index: number,
    value: string | number | null | undefined,
  ) => {
    setNewAnswerData(
      newAnswerData?.map((data, i) => (i === index ? value : data)),
    );
  };
  const [isLoading, setIsLoading] = useState(false);

  const success = () => {
    setToast("データを更新しました", true);
    setIsOpen(false);
  };

  const handleClick = async () => {
    setIsLoading(true);
    const data: OverrideValue = {
      questionnaireId: questionnaireId,
      patientId: tableBody.id,
      newData: newAnswerData,
    };
    // APIの処理
    const response = await putQuestionnaireValueOverride(data);
    !!response ? success() : setToast("データ更新に失敗しました", false);
    !!handleReload && handleReload();
    setIsLoading(false);
  };

  // tbodyの中身の作成
  let answers: JSX.Element[] = [];
  tableBody.answer.forEach((answer, index) => {
    if (typeof answer === "number") {
      if (tableHead[index].type === 0) {
        answers.push(
          <td key={index}>
            <AnswerEditDropDown
              answer={answer}
              options={tableHead[index].choice.filter((data) => data)}
              onChange={(option: number) => updateAnswerData(index, option + 1)}
            />
          </td>,
        );
      } else if (tableHead[index].type === 2) {
        answers.push(
          <td key={index}>
            <TextBoxNumber
              index={index}
              value={answer}
              updateAnswerData={updateAnswerData}
            />
          </td>,
        );
      }
    } else if (typeof answer === "string") {
      answers.push(
        <td key={index}>
          <TextBoxText
            index={index}
            value={answer}
            updateAnswerData={updateAnswerData}
          />
        </td>,
      );
    } else if (answer === undefined || answer === null) {
      if (
        tableHead[index].display === 0 ||
        (tableHead[index].display === 1 && tableBody.sex === 0) ||
        (tableHead[index].display === 2 && tableBody.sex === 1)
      ) {
        if (tableHead[index].type === 0) {
          answers.push(
            <td key={index}>
              <AnswerEditDropDown
                answer={
                  tableHead[index].choice.filter((data) => data).length + 1
                }
                options={[
                  ...tableHead[index].choice.filter((data) => data),
                  "選択してください",
                ]}
                onChange={(option: number) => {
                  option < tableHead[index].choice.filter((data) => data).length
                    ? updateAnswerData(index, option + 1)
                    : updateAnswerData(index, null);
                }}
              />
            </td>,
          );
        } else if (tableHead[index].type === 1) {
          answers.push(
            <td key={index}>
              <TextBoxText
                index={index}
                value={""}
                updateAnswerData={updateAnswerData}
              />
            </td>,
          );
        } else if (tableHead[index].type === 2) {
          answers.push(
            <td key={index}>
              <TextBoxNumber
                index={index}
                value={0}
                updateAnswerData={updateAnswerData}
              />
            </td>,
          );
        }
      } else {
        answers.push(<td key={index}>-</td>);
      }
    }
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="AnswerEditor">
        {/* <Text size="h3" color="gray_600">
          回答情報の修正
        </Text> */}
        <PersonProfile sex={tableBody.sex} carte={tableBody.carte} />
        <div className="table">
          <table>
            <thead>
              <tr>
                {tableHead?.map((thead, index) => (
                  <th key={index}>
                    <TextCompresser text={thead.text} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>{answers}</tr>
            </tbody>
          </table>
        </div>
        <LeadButton
          value="更新する"
          onClick={handleClick}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

// drop-downの作成
const AnswerEditDropDown = ({
  answer,
  options,
  onChange,
}: {
  answer: number;
  options: Array<string>;
  onChange: (option: number) => void;
}) => {
  const [value, setValue] = useState(answer - 1);
  return (
    <DropDown
      value={value}
      options={options}
      onChange={(option: number) => {
        onChange(option);
        setValue(option);
      }}
    />
  );
};

// テキストボックスの作成
const TextBoxText = ({
  index,
  value,
  updateAnswerData,
}: {
  index: number;
  value: string;
  updateAnswerData: (
    index: number,
    value: string | number | null | undefined,
  ) => Promise<void>;
}) => {
  const [text, setText] = useState(value);
  useEffect(() => {
    updateAnswerData(index, text);
  }, [text]);
  return <TextBox value={text} handle={setText} />;
};

// テキストボックス（半角数字）の作成
const TextBoxNumber = ({
  index,
  value,
  updateAnswerData,
}: {
  index: number;
  value: number;
  updateAnswerData: (
    index: number,
    value: string | number | null | undefined,
  ) => Promise<void>;
}) => {
  const [number, setNumber] = useState(`${value}`);
  useEffect(() => {
    updateAnswerData(index, parseInt(number));
  }, [number]);
  return <TextBox value={number} isNum={true} handle={setNumber} />;
};
