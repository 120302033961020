import React, { ReactNode, useContext, useState } from "react";

import { ConfirmDialog, ConfirmDialogType } from "components/confirm-dialog";

export type ConfirmDialogContext = {
  setConfirmDialog: (value: ConfirmDialogType) => void;
  setIsOpen: (value: boolean) => void;
};

const ConfirmDialogContext = React.createContext<ConfirmDialogContext>({
  setConfirmDialog: () => {
    console.log("Confirm-provider unimplement.");
  },
  setIsOpen: () => {
    console.log("Confirm-provider unimplement.");
  },
});

export const ConfirmContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>();
  // const setConfirmDialogContent = (confirmDialogContent: ConfirmDialogType) => {
  //   setConfirmDialog(confirmDialogContent);
  // };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ConfirmDialogContext.Provider
      value={{
        setConfirmDialog,
        setIsOpen,
      }}
    >
      {children}
      {!!confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          text={confirmDialog.text}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleClick={confirmDialog.handleClick}
        />
      )}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmContext = (): ConfirmDialogContext => {
  return useContext(ConfirmDialogContext);
};
