import classNames from "classnames";
import { ReactNode, useState } from "react";
import "./index.scss";

export const DragAndDropField = ({
  data,
  handleDragEnter,
}: {
  data: Array<ReactNode>;
  handleDragEnter: {
    arr: Array<any>;
    setArr: (arr: Array<any>) => void;
  };
}) => {
  const [dragIndex, setDragIndex] = useState<number>();
  const [insertIndex, setInsertIndex] = useState<number>();
  const dragStart = (index: number) => {
    setDragIndex(index);
  };

  const dragEnter = async (index: number) => {
    setInsertIndex(index);
  };

  const dragEnd = async () => {
    if (
      insertIndex !== dragIndex &&
      dragIndex !== undefined &&
      insertIndex !== undefined
    ) {
      const arr: Array<any> = [];
      handleDragEnter.arr.forEach((element) => {
        arr.push(element);
      });
      await arr.splice(dragIndex, 1);
      await arr.splice(insertIndex, 0, handleDragEnter.arr[dragIndex]);
      handleDragEnter.setArr(arr);

      await setDragIndex(insertIndex);
      setDragIndex(undefined);
      setInsertIndex(undefined);
    } else {
      setDragIndex(undefined);
      setInsertIndex(undefined);
    }
  };
  return (
    <div className={"DragAndDropField"}>
      {data.map((element, index) => {
        const classes = classNames([
          "d-n-d-element",
          index === dragIndex && "dragging",
          index !== dragIndex &&
            index === insertIndex &&
            dragIndex !== undefined &&
            index < dragIndex &&
            "emp-top",
          index - 1 !== dragIndex &&
            index - 1 === insertIndex &&
            dragIndex !== undefined &&
            index - 1 > dragIndex &&
            "emp-top",
          index === data.length - 1 &&
            index !== dragIndex &&
            index === insertIndex &&
            dragIndex !== undefined &&
            index > dragIndex &&
            "emp-bottom",
        ]);
        return (
          <div
            key={index}
            draggable={true}
            onDragStart={() => dragStart(index)}
            onDragEnter={() => dragEnter(index)}
            onDragOver={(event) => event.preventDefault()}
            onDragEnd={() => {
              dragEnd();
            }}
            className={classes}
          >
            {element}
          </div>
        );
      })}
    </div>
  );
};
