import React, { useEffect, useState } from "react";

import "./index.scss";
import { Text } from "ui/atoms/text";
import {
  CarteIdListType,
  UpdatePatientGroupEditor,
} from "types/patientGroupListTypes";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { BuildBlock } from "ui/molecules/build-block";
import { Modal } from "ui/molecules/modal";
import { TextArea } from "ui/molecules/text-area";
import { TextBox } from "ui/molecules/text-box";
import {
  TextButton,
  textButtonIcon,
  textButton,
} from "ui/molecules/text-button";
import { setToast } from "ui/molecules/toast";
import { getCarteIdList } from "data/api/getCarteIdList";
import { putPatientGroupUpdate } from "data/api/putPatientGroupUpdate";
import { TextAreaForPatientIdList } from "ui/molecules/text-area-for-patient-id-list";
import { useSecondConfirmContext } from "components/second-confirm-dialog-provider";
import { PatientGroupChecker } from "components/common/patient-group-checker";

export const PatientGroupListEditBox = ({
  patientGroupId,
  patientGroupName,
  patientGroupInformation,
  isOpen,
  setIsOpen,
}: {
  patientGroupId: number;
  patientGroupName: string;
  patientGroupInformation: string;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [carteIdList, setCarteIdList] = useState<CarteIdListType>();
  const [receivedPatientGroupName] = useState(patientGroupName);
  const [receivedPatientGroupInformation] = useState(patientGroupInformation);
  const [editCarteIdList, setEditCarteIdList] = useState<string>("");
  const [editPatientGroupName, setEditPatientGroupName] = useState<string>("");
  const [editPatientGroupInformation, setEditPatientGroupInformation] =
    useState<string>("");
  const { innerSetConfirmDialog, innerSetIsOpen } = useSecondConfirmContext();
  // 備考欄の最大行数
  const PATIENT_LIST_INFO_MAX_ROWS = 3;
  const PATIENT_LIST_INFO_MAX_COLUMNS = 40;
  // 患者グループ対象者の最大行数
  const PATIENT_LIST_TEXT_MAX_ROWS = 18;
  const PATIENT_LIST_TEXT_MAX_COLUMNS = 40;
  // 正常結果：
  const IS_NOT_DUPLICATE = "";
  // 患者グループ名の文字数上限
  const PATIENT_GROUP_NAME_LIMIT = 100;
  // 備考欄の文字数上限
  const PATIENT_GROUP_NOTE_LIMIT = 500;
  // 文字数０桁の定数
  const STRING_LENGTH_ZERO = 0;
  // 患者グループIDで紐づくカルテIDを取得し、また患者グループ名と患者グループの備考欄情報を設置する処理
  useEffect(() => {
    (async () => {
      const response = await getCarteIdList(patientGroupId);
      setCarteIdList(response);
      if (receivedPatientGroupName) {
        setEditPatientGroupName(receivedPatientGroupName);
      }
      if (receivedPatientGroupInformation) {
        setEditPatientGroupInformation(receivedPatientGroupInformation);
      }
    })();
  }, []);
  // カルテIDが存在したら患者グループ対象者のテキストボックスに設定する処理
  useEffect(() => {
    if (carteIdList) {
      setEditCarteIdList(carteIdList.carteId);
    }
  });
  // ユーザーが患者グループ対象者のカルテIDを編集すると反映する処理
  useEffect(() => {
    setEditCarteIdList(editCarteIdList);
  }, [editCarteIdList]);

  // 患者グループ編集の「保存する」ボタンを押下する処理
  const handleUpdate = async () => {
    if (!!!editPatientGroupName) {
      setToast("患者グループ名を入力してください。", false, 5000);
      return;
    }
    if(editPatientGroupName.length > PATIENT_GROUP_NAME_LIMIT){
      setToast("グループ名を100文字以内で入力してください。", false, 5000);
      return;
    }
    if(editPatientGroupInformation.length > PATIENT_GROUP_NOTE_LIMIT){
      setToast("備考欄を500文字以内で入力してください。", false, 5000);
      return;
    }
    // 患者グループ対象者に入力がある場合
    if(editCarteIdList.length > STRING_LENGTH_ZERO){
      // 患者グループ対象者の入力値を改行("\n")で分けて、文字数を確認する処理
      let editCarteIdListEmptyChecker = editCarteIdList.split("\n",)
      // 複数の空改行のチェック処理
      for (let carteId of editCarteIdListEmptyChecker) {
        if(carteId.length == STRING_LENGTH_ZERO){
          setToast("患者グループ対象者は空の改行が入っています。空の改行を消してください。", false, 5000);
          return;
        }
      }
    }

    // 入力したカルテIDが重複するかチェックする処理
    const response = await PatientGroupChecker(
      editPatientGroupName,
      editCarteIdList,
    );
    // 透明文字が患者グループ名にある場合
    if (!response) {
      return;
    }
    // 同じ患者グループ名で更新する場合、
    if (response?.duplicateGroupName != receivedPatientGroupName) {
      if (response.isDuplicateGroupName == true) {
        setToast(
          "患者グループ名が重複しています。もう一度入力してください。",
          false,
          5000,
        );
        return;
      } else if (
        response?.duplicateInfo == IS_NOT_DUPLICATE &&
        response?.carteIdNotExistInfo == IS_NOT_DUPLICATE
      ) {
        patientGroupListUpdate();
      } else {
        innerSetConfirmDialog({
          title: "",
          text: [response.duplicateInfo],
          secondText: [],
          thirdText: [response.carteIdNotExistInfo],
          fourthText: [],
          handleClick: async () => {
            patientGroupListUpdate();
          },
        });
        innerSetIsOpen(true);
      }
      // グループ名が重複してない場合
    } else if (response?.duplicateGroupName) {
      // 患者グループ名が重複しない・カルテIDの存在・重複チェック正常結果
      if (
        response?.duplicateInfo == IS_NOT_DUPLICATE &&
        response?.carteIdNotExistInfo == IS_NOT_DUPLICATE
      ) {
        patientGroupListUpdate();
      } else {
        innerSetConfirmDialog({
          title: "",
          text: [response.duplicateInfo],
          secondText: [],
          thirdText: [response.carteIdNotExistInfo],
          fourthText: [],
          handleClick: async () => {
            patientGroupListUpdate();
          },
        });
        innerSetIsOpen(true);
      }
    }
  };

  // 患者グループ更新処理
  const patientGroupListUpdate = async () => {
    const data: UpdatePatientGroupEditor = {
      patientGroupId: patientGroupId,
      patientGroupName: editPatientGroupName,
      patientGroupInformation: editPatientGroupInformation,
      carteIdList: editCarteIdList.replaceAll("\n", ","),
    };
    const result = await putPatientGroupUpdate(data);
    !!result
      ? success()
      : setToast("グループのデータの更新が失敗しました。", false);
  };
  const success = () => {
    setToast("グループのデータを更新しました", true, 6000);
    setTimeout(() => {
      window.location.reload();
      setIsOpen(false);
    }, 1000);
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="PatientGroupEditor">
        <div className="PatientEditorDialogue">
          <div className="patient-editor-title">
            <Text size={"h3"}>患者グループ編集</Text>
          </div>
          <InternalScroll className="dialog-content">
            <BuildBlock title="グループ名">
              <TextBox
                className="patient-list-group-name"
                value={editPatientGroupName}
                handle={setEditPatientGroupName}
                placeholder={"患者グループ名"}
              />
            </BuildBlock>
            <BuildBlock title="備考欄">
              <TextArea
                className="patient-list-note"
                value={editPatientGroupInformation}
                handle={setEditPatientGroupInformation}
                placeholder={"備考の情報を入力してください"}
                rows={PATIENT_LIST_INFO_MAX_ROWS}
                cols={PATIENT_LIST_INFO_MAX_COLUMNS}
              />
            </BuildBlock>
            <BuildBlock
              title="患者グループ対象者"
              exp="患者IDを編集してください。"
            >
              <TextAreaForPatientIdList
                className="patient-list-text-box"
                value={editCarteIdList.split(",").join("\n")}
                handle={setEditCarteIdList}
                placeholder={
                  "カルテIDを入力してください。 \n例:\n00000001\n00000002\n00000003"
                }
                rows={PATIENT_LIST_TEXT_MAX_ROWS}
                cols={PATIENT_LIST_TEXT_MAX_COLUMNS}
              />
            </BuildBlock>
            <TextButton
              value="保存する"
              icon={textButtonIcon.save}
              onClick={() => {
                handleUpdate();
              }}
              className={"editor-save-button"}
              type={textButton.black}
            />
          </InternalScroll>
        </div>
      </div>
    </Modal>
  );
};
