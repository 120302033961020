import { client } from "data/axios";
import { QuoDataParticipantSummaryType } from "types/patientGroupListTypes";
import { TableHead } from "types/types";

export type ParticipantType = {
  id: string;
  carte: string;
  sex: number;
  birth: string;
  datas: Array<AnswerData>;
  quoInfo: Array<QuoDataParticipantSummaryType>;
  answeredQuestionnaireCount: number;
  questionnaireDeliveriesCount:number;
};

type AnswerData = {
  id: number;
  name: string;
  type: number;
  anonymous: number;
  patientGroupId: number;
  patientGroupName: string;
  tableHead: Array<TableHead>;
  tableBody: Array<string | number | null | undefined>;
};
/**
 * API名：getParticipant
 * 目的：選択された患者のデータを取得する処理
 * @param carte 
 * @returns 患者の情報
 */
export async function getParticipant(
  carte: string
): Promise<ParticipantType | undefined> {
  try {
    const url = `/participant/${carte}`;
    const response = await client.get<ParticipantType>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
