import { client } from "data/axios";

export async function getParticipantsRequiredLine(): Promise<
  Array<string> | undefined
> {
  try {
    const url = `/participants-required-line`;
    const response = await client.get<Array<string>>(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
