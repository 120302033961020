import classNames from "classnames";
import { ReactNode } from "react";
import "./index.scss";

export const SideBar = ({
  children,
  className,
  isActive,
}: {
  children: ReactNode;
  className?: string;
  isActive: boolean;
}) => {
  const classes = classNames([
    "SideBar",
    className && className,
    isActive && "active",
  ]);
  return <div className={classes}>{children}</div>;
};
