import React, { ReactNode, useContext, useState } from "react";

import {
  SecondConfirmDialog,
  SecondConfirmDialogType,
} from "components/second-confirm-dialog";

/**
 * 処理の目的：
 *
 * アンケートのカルテID重複チェックと患者グループのカルテID重複チェックするための処理
 */
export type SecondConfirmDialogContext = {
  innerSetConfirmDialog: (value: SecondConfirmDialogType) => void;
  innerSetIsOpen: (value: boolean) => void;
};

const SecondConfirmDialogContext =
  React.createContext<SecondConfirmDialogContext>({
    innerSetConfirmDialog: () => {
      console.log("Confirm-provider unimplement.");
    },
    innerSetIsOpen: () => {
      console.log("Confirm-provider unimplement.");
    },
  });

export const SecondConfirmContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [innerConfirmDialog, innerSetConfirmDialog] =
    useState<SecondConfirmDialogType>();
  const [innerIsOpen, innerSetIsOpen] = useState(false);
  return (
    <SecondConfirmDialogContext.Provider
      value={{
        innerSetConfirmDialog,
        innerSetIsOpen,
      }}
    >
      {children}
      {!!innerConfirmDialog && (
        <SecondConfirmDialog
          title={innerConfirmDialog.title}
          text={innerConfirmDialog.text}
          secondText={innerConfirmDialog.secondText}
          thirdText={innerConfirmDialog.thirdText}
          fourthText={innerConfirmDialog.fourthText}
          innerIsOpen={innerIsOpen}
          innerSetIsOpen={innerSetIsOpen}
        />
      )}
    </SecondConfirmDialogContext.Provider>
  );
};

export const useSecondConfirmContext = (): SecondConfirmDialogContext => {
  return useContext(SecondConfirmDialogContext);
};
