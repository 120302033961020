import { client } from "data/axios";
import { DeletePatientGroup } from "types/patientGroupListTypes";

// 患者グループ削除の結果
type PatientGroupDeleteResultTypes = {
  result: string;
};
/**
 * API名：patientGroupDelete
 * 目的：患者グループの削除フラグを１にするAPI
 * @param deletePatientGroupData 
 * @returns 
 */
export async function putPatientGroupDelete(
  deletePatientGroupData: DeletePatientGroup
): Promise<PatientGroupDeleteResultTypes | undefined>
{
  try {
    const url = "/patient-group/delete";
    const response = await client.put(url, deletePatientGroupData);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
