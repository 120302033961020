import { useEffect, useState } from "react";
import "./index.scss";
import { useTabContext } from "components/tab-provider";
import { Text } from "ui/atoms/text";
import { postQuestionnaireBuild } from "data/api/postQuestionnaireBuild";
import { setToast } from "ui/molecules/toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QuestionnaireOverView,
  Question,
  QuestionnaireDetail,
  QuestionnaireNameData,
} from "types/types";
import { QuestionnaireEditor } from "components/questionnaire-editor";
import { LoadingPage } from "components/loading-page";
import {
  CheckCarteIdData,
  PatientGroupListType,
} from "types/patientGroupListTypes";
import { getPatientGroupList } from "data/api/getPatientGroupList";
import React from "react";
import { postPatientGroupListCarteIdDuplicateCheck } from "data/api/postPatientGroupListCarteIdDuplicateCheck";
import { useConfirmContext } from "components/confirm-dialog-provider";
import { postQuestionnaireNameDuplicateCheck } from "data/api/postQuestionnaireNameDuplicateCheck";
import { Wall } from "ui/molecules/wall";

let questionTextLengthResult = false; // アンケート本文の文字数判定
const QUESTIONNAIRE_NAME_MAX_LENGTH = 100; // アンケート名の最大文字数
const QUESTION_TEXT_MAX_LENGTH = 160; // アンケート本文最大文字数
const INVISIBLE_CHARACTER = "‎"; // 透明文字（キーボード入力不可）

export const QuestionnaireBuild = () => {
  // 遷移元から引数を受け取る
  const location = useLocation();
  const state = location.state as QuestionnaireDetail;
  const { setHeaderContent } = useTabContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patientGroupId, setPatientGroupId] = useState<Array<number>>([]);
  const [patientGroupName, setPatientGroupName] = useState<Array<string>>([]);

  useEffect(() => {
    document.title = "新規アンケート作成";
    setHeaderContent({
      mainHeader: "新規アンケート作成",
      subHeader: "",
    });
    setIsLoading(true);
    (async () => {
      const response = await getPatientGroupList();
      setIsLoading(false);
      !!response ? getSuccess(response) : setIsError(true);
    })();

    // 遷移元の画面から引数をもらっていた場合（複製の場合）
    if (state != null) {
      setName(state.name);
      setType(state.type);
      setAnonymous(state.anonymous);
      setQuestions(state.questions);

      setPatientGroupId(state.patientGroupId);
      setPatientGroupName(state.patientGroupName);

      document.title = "アンケート複製";
      setHeaderContent({
        mainHeader: "アンケート複製",
        subHeader: "",
      });
    }
  }, []);
  const navigation = useNavigate();
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  // 各パラメータのステート設定
  const [name, setName] = useState("");
  const [type, setType] = useState(1);
  const [anonymous, setAnonymous] = useState(0);
  const [patientGroupList, setPatientGroupList] = useState<
    Array<PatientGroupListType>
  >([]);
  const [selectedPatientGroupName, setSelectedPatientGroupName] =
    React.useState<string[]>([]);
  const [questions, setQuestions] = useState<Array<Question>>([
    {
      num: 0,
      text: "",
      property: 0,
      display: 0,
      choices: [
        { text: "", next: 2 },
        { text: "", next: 2 },
      ],
    },
  ]);
  let patientGroupIdList: number[] = [];
  let patientGroupNameList: string[] = [];
  // 重複チェック結果: 重複
  const IS_DUPLICATE = "IS_DUPLICATE";

  const success = () => {
    setToast("アンケートを保存しました", true);
    navigation("/questionnaire-bundle");
  };

  const handleClick = async () => {
    setIsLoading(true);
    if (name.length > QUESTIONNAIRE_NAME_MAX_LENGTH){
      setToast(
        "アンケート名は100文字以内で入力してください。",
        false,
        2000,
      );
      setIsLoading(false);
      return;
    }
    const checkName: QuestionnaireNameData = {
      questionnaireName: name,
    };
    // アンケート名重複チェック(新規作成・複製時)
    const questionnaireNameCheck =
      await postQuestionnaireNameDuplicateCheck(checkName);

    // アンケートの重複ステータスがtrueの場合、エラーを返す
    if (questionnaireNameCheck?.duplicateStatus == true) {
      setToast(
        "アンケート名が重複しています。もう一度入力してください。",
        false,
        5000,
      );
      setIsLoading(false);
      return;
    }

    // アンケート本文入力文字数チェック
    questions.map((question) => {
      if (question.text.length > QUESTION_TEXT_MAX_LENGTH) {
        questionTextLengthResult = true;
      }
    });

    if (questionTextLengthResult) {
      setToast(
        "アンケート本文の文字数制限を超えている質問文があります。",
        false,
        2000,
      );
      questionTextLengthResult = false;
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    // 項目「患者グループ」の入力チェック
    for (let i = 0; i < selectedPatientGroupName.length; i++) {
      patientGroupIdList.push(
        Number(selectedPatientGroupName[i].split(INVISIBLE_CHARACTER).pop()),
      );
      patientGroupNameList.push(
        selectedPatientGroupName[i].split(INVISIBLE_CHARACTER, 1).toString(),
      );
    }

    if (patientGroupIdList.length == 0) {
      setToast("患者グループを選択してください。", false, 2000);
      setIsLoading(false);
      setSelectedPatientGroupName(selectedPatientGroupName);
      patientGroupIdList = [];
      patientGroupNameList = [];
      return;
    } else if (
      patientGroupIdList.length == 1 &&
      patientGroupIdList.includes(0)
    ) {
      questionnaireRegister();
    } else if (
      patientGroupIdList.length > 1 &&
      patientGroupIdList.includes(0)
    ) {
      setToast(
        "全員に配信する場合、患者グループを指定しないでください。",
        false,
        2000,
      );
      setIsLoading(false);
      setSelectedPatientGroupName(selectedPatientGroupName);
      patientGroupIdList = [];
      patientGroupNameList = [];
      return;
    } else {
      const checkData: CheckCarteIdData = {
        patientGroupIdList: patientGroupIdList,
      };
      const checkResult =
        await postPatientGroupListCarteIdDuplicateCheck(checkData);
      let carteIdDuplicateInfo = "";
      if (checkResult?.carteIdDuplicateResult == IS_DUPLICATE) {
        carteIdDuplicateInfo = `患者グループが複数指定されていることにより、\n以下のカルテIDが重複しています。\n ${checkResult?.carteId}`;
        setConfirmDialog({
          title: "登録します。よろしいですか？",
          text: [carteIdDuplicateInfo],
          handleClick: async () => {
            questionnaireRegister();
          },
        });
        setIsLoading(false);
        setIsOpen(true);
      } else {
        questionnaireRegister();
      }
    }
  };
  const questionnaireRegister = async () => {
    const data: QuestionnaireOverView = {
      name: name,
      type: type,
      anonymous: anonymous,
      patientGroupId: patientGroupIdList,
      questions: questions.map((question, index) => {
        const data: Question = {
          num: index + 1,
          text: question.text,
          property: question.property,
          display: question.display,
          choices: question.property === 0 ? question.choices : [],
        };
        return data;
      }),
    };
    const result = await postQuestionnaireBuild(data);
    !!result ? success() : setToast("アンケートの保存に失敗しました", false);
    setIsLoading(false);
  };

  // 初回表示用患者グループ（空要素）
  const defaultData: PatientGroupListType = {
    patientGroupId: 0,
    patientGroupName: "全員",
    patientCount: 0,
    patientGroupInformation: "",
    dataBuild: new Date(),
    dataUpdate: new Date(),
    flag: 0,
  };
  // 患者グループ一覧の取得
  const getSuccess = (data: Array<PatientGroupListType>) => {
    data.unshift(defaultData); // 患者グループの配列0番目に空要素を追加
    setPatientGroupList(data);
  };
  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <Wall value="新規アンケート作成の読み込みに失敗しました" icon="fail" />
  ) : (
    <div className="QuestionnaireBuild">
      <Text size={"h5"} color="gray_600" className="exp">
        新たに作成するアンケートの項目を設定してください
      </Text>
      <QuestionnaireEditor
        name={name}
        setName={setName}
        type={type}
        setType={setType}
        anonymous={anonymous}
        setAnonymous={setAnonymous}
        patientGroupIdList={patientGroupId}
        patientGroupList={patientGroupList}
        patientGroupNameList={patientGroupName}
        selectedPatientGroupName={selectedPatientGroupName}
        setSelectedPatientGroupName={setSelectedPatientGroupName}
        questions={questions}
        setQuestions={setQuestions}
        onClick={() => {
          handleClick();
        }}
        isNewBuild={true}
      />
    </div>
  );
};
