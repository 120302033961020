import { Tooltip } from "@mui/material";
import { ReactElement } from "react";

export const ToolTip = ({
  title,
  children,
}: {
  title: string;
  children: ReactElement<any, any>;
}) => {
  return <Tooltip title={title}>{children}</Tooltip>;
};
