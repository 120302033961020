import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import Logo from "assets/image/logo.svg";

import { AccountData } from "types/types";
import { Text } from "ui/atoms/text";
import { TextForm } from "ui/molecules/text-form";
import { LeadButton } from "ui/molecules/lead-button";
import { setToast } from "ui/molecules/toast";
import { PasswordForm } from "ui/molecules/password-form";
import { postAccountSignin } from "data/api/postAccountSignin";
import { useAuthContext } from "components/auth-provider";
import { Badge } from "ui/atoms/badge";

export const SignIn = () => {
  useEffect(() => {
    document.title = "ログイン";
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { signIn } = useAuthContext();
  const navigation = useNavigate();
  const certification = (response: AccountData) => {
    signIn(response.user, response.admin);
    setToast("ログインしました", true);
    navigation("/");
  };
  const failure = () => {
    setToast("ログインに失敗しました", false);
    setIsLoading(false);
  };

  const handleClick = async () => {
    if (process.env.REACT_APP_MODE === "mtn") {
      // メンテナンスモード
      if (
        (email === "QA" || email === "qa") &&
        (password === "QA" || password === "qa")
      ) {
        setIsLoading(true);
        certification({
          user: "システム メンテナンス ユーザー",
          admin: true,
        });
      } else {
        setToast(
          "ただいまメンテナンス中のためシステムを利用することができません",
        );
      }
    } else if (process.env.REACT_APP_MODE === "admtn") {
      // admin権限なしユーザーのみメンテナンスモード
      if (
        email === "ykakuta@med.tohoku.ac.jp" ||
        email === "keito.tamura.s1@dc.tohoku.ac.jp"
      ) {
        if (!!!password) {
          setToast("パスワードを入力してください", false);
        } else {
          setIsLoading(true);
          const response = await postAccountSignin(email, password);
          !!response
            ? certification({
                user: "システム メンテナンス ユーザー",
                admin: true,
              })
            : failure();
        }
      } else {
        setToast(
          "ただいまメンテナンス中のためシステムを利用することができません",
        );
      }
    } else {
      // 通常はこちら
      if (!!!email) {
        setToast("メールアドレスを入力してください", false);
      } else if (!!!password) {
        setToast("パスワードを入力してください", false);
      } else {
        setIsLoading(true);
        const response = await postAccountSignin(email, password);
        !!response ? certification(response) : failure();
      }
    }
  };

  const mode = process.env.REACT_APP_MODE;
  return (
    <div className="signin">
      <div className="logo">
        <img src={Logo} alt="" />
        {mode !== "prd" && !!mode && <Badge type={mode} />}
      </div>
      <Text size="h2" color="gray_800" className="signin__title">
        IBD-LINQ管理画面ログイン
      </Text>
      <TextForm
        type="email"
        value={email}
        placeholder="メールアドレス"
        onChange={setEmail}
        onKeyDown={handleClick}
      />
      <PasswordForm
        value={password}
        onChange={setPassword}
        onKeyDown={handleClick}
      />
      <LeadButton
        value="ログイン"
        onClick={handleClick}
        isLoading={isLoading}
      />
    </div>
  );
};
