import { useLocation, useNavigate } from "react-router-dom";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { setToast } from "ui/molecules/toast";
import { useAuthContext } from "components/auth-provider";
import { useTabContext } from "components/tab-provider";
import { useLayoutContext } from "..";
import { ChevronsRight } from "react-feather";
import { TextButton } from "ui/molecules/text-button";
import { useConfirmContext } from "components/confirm-dialog-provider";

export type HeaderContent = {
  mainHeader: string;
  subHeader: string;
  onClick?: () => void;
};

export const Header = () => {
  const { header } = useTabContext();
  const { sidebar, setSidebar } = useLayoutContext();
  return (
    <div className="Header">
      <div
        className="headerContent"
        onClick={() => {
          header?.onClick && header.onClick();
        }}
      >
        {!sidebar && (
          <div
            className="chevrons"
            onClick={() => {
              setSidebar(true);
            }}
          >
            <ChevronsRight />
          </div>
        )}
        <Text size="h3" color="gray_800">
          {header?.mainHeader}
        </Text>
        <Text size="h5" color="gray_700">
          {header?.subHeader}
        </Text>
      </div>
      <SignOutButton />
    </div>
  );
};

const SignOutButton = () => {
  const { signOut } = useAuthContext();
  const navigation = useNavigate();

  // アンケートビルドページの場合のみアラート
  const location = useLocation();
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  const alertTransitionFromBuild = (onClick: () => void) => {
    setConfirmDialog({
      title: "ログアウトしてよろしいですか？",
      text: ["編集した内容は保存されません"],
      handleClick: onClick,
    });
    setIsOpen(true);
  };
  const handleClick = () => {
    location.pathname !== "/questionnaire-build" &&
    location.pathname.indexOf("/questionnaire-bundle/") === -1
      ? handleSignOut()
      : alertTransitionFromBuild(() => {
          handleSignOut();
        });
  };
  const handleSignOut = () => {
    signOut();
    navigation("/signin");
    setToast("ログアウトしました", true);
  };
  return (
    <TextButton
      value={"ログアウト"}
      onClick={handleClick}
    />
  );
};
