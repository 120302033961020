import classNames from "classnames";
import { ReactNode } from "react";
import { ArrowLeft, Minus, Plus } from "react-feather";
import "./index.scss";

export const IconButton = ({
  value,
  onClick,
  className,
  isStatus = false,
  disable = false,
}: {
  value: iconButtonType;
  onClick: () => void;
  className?: string;
  icon?: ReactNode;
  isStatus?: boolean;
  disable?: boolean;
}) => {
  const classes = classNames([
    "IconButton",
    className,
    isStatus && "active",
    disable && "disable",
  ]);
  return (
    <button className={classes} onClick={!disable ? onClick : () => {}}>
      <div className={"button-icon"}>{value}</div>
    </button>
  );
};

export type iconButtonType = (typeof iconButton)[keyof typeof iconButton];
export const iconButton = {
  arrowLeft: <ArrowLeft size={20} />,
  plus: <Plus size={20} />,
  minus: <Minus size={20} />,
};
