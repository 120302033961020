import classNames from "classnames";
import { ReactNode } from "react";
import "./index.scss";

// styleにheightが不要なクラス名
const NO_HEIGHT_SETTING_CLASS_NAME = "selected-internal-no-height";
// 空文字列
const EMPTY_STRING = "";

export const InternalScroll = ({
  children,
  className,
  height = "100%",
}: {
  children: ReactNode;
  className?: string;
  height?: string;
}) => {
  const classes = classNames(["InternalScroll", className && className]);
  return (
    <div
      className={classes}
      style={{
        height:
          className == NO_HEIGHT_SETTING_CLASS_NAME ? EMPTY_STRING : height,
      }}
    >
      {children}
    </div>
  );
};
