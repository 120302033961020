import "./index.scss";

export const Badge = ({ type }: { type: string }) => {
  return (
    <span className="Badge">
      {/* <div>{type}</div> */}
      {type}
    </span>
  );
};
