import { QuestionnaireData, TableHead, TableBody } from "types/types";

// CSVダウンロード
export const CsvDownload = async (
  csvDataList: QuestionnaireData,
  status: string,
) => {
  const csvRows = [];
  // ヘッダー行の作成
  let head = "性別,カルテID,";
  const headers = await csvDataList.tableHead.map((csvHead, i) => {
    head += csvHead.text + ",";
  });
  // 不要カンマ削除
  head = head.slice(0, -1);
  // ヘッダー行を追加
  csvRows.push(head);
  // データ行を作成
  const data = await dataListCreate(
    csvDataList.tableHead,
    csvDataList.tableBody,
    csvRows,
  );
  // ファイルを出力
  const output = await csvOutput(data, csvDataList.name, status);
};

// CSVデータ部分の処理
const dataListCreate = async (
  headList: TableHead[],
  bodyList: TableBody[],
  rows: string[],
) => {
  await Promise.all(
    bodyList.map((csvData, j) => {
      // 性別の判定（0:男性、1:女性)
      let sexStr = "";
      if (csvData.sex == 0) {
        sexStr = "男性";
      } else if (csvData.sex == 1) {
        sexStr = "女性";
      } else {
        // 性別が入らないことはあるのか？一応設定
        sexStr = "-";
      }
      // 性別とカルテIDの結合
      let dataStr = sexStr + "," + csvData.carte + ",";
      // 回答数分、画面内容となるように選択肢判定する
      const answerStr = csvData.answer.map((answerData, index) => {
        if (typeof answerData === "number") {
          if (headList[index].type === 0) {
            dataStr += headList[index].choice[answerData - 1];
          } else if (headList[index].type === 2) {
            dataStr += answerData;
          }
        } else if (typeof answerData === "string") {
          dataStr += answerData;
        } else if (typeof answerData === undefined || answerData === null) {
          dataStr += "-";
        }
        dataStr += ",";
      });
      // 不要カンマ削除
      dataStr = dataStr.slice(0, -1);
      rows.push(dataStr);
    }),
  );
  return rows;
};

// CSV出力処理
const csvOutput = async (
  dataList: string[],
  fileName: string,
  status: string,
) => {
  // 現在日時取得
  const dateTime = new Date();
  const dateStr =
    dateTime.getFullYear() +
    ("0" + (dateTime.getMonth() + 1)).slice(-2) +
    ("0" + dateTime.getDate()).slice(-2) +
    ("0" + dateTime.getHours()).slice(-2) +
    ("0" + dateTime.getMinutes()).slice(-2) +
    ("0" + dateTime.getSeconds()).slice(-2);

  // CSVデータの文字列化: UTF-8 BOMを先頭に追加し、行ごとに改行で結合
  const csvData = "\uFEFF" + dataList.join("\n");
  // Blobオブジェクトの作成: CSVデータをテキストファイルとして扱う
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  // Blobオブジェクトからダウンロード用URLの生成
  const url = window.URL.createObjectURL(blob);
  // ダウンロード用のaタグを作成し、設定
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  // ダウンロードファイル指定（ファイル名_回答済or回答中_現在日時(YYYYmmddhhmmss).csv)
  a.setAttribute("download", `${fileName}_${status}_${dateStr}.csv`);
  // aタグをDOMに追加し、クリックイベントを発火させることでダウンロードを実行
  document.body.appendChild(a);
  a.click();
  // ダウンロード後、aタグをDOMから削除
  document.body.removeChild(a);
};
