import {
  ArrowLeftCircle,
  Frown,
  Search,
  File,
  UserPlus,
  FileMinus,
} from "react-feather";

import "./index.scss";

import { Text } from "ui/atoms/text";
import classNames from "classnames";

export const Wall = ({
  value,
  icon,
  className,
}: {
  value: string;
  icon: "arrow" | "search" | "fail" | "file" | "select" | "null";
  className?: string;
}) => {
  const Icon = (icon: string) => {
    switch (icon) {
      case "arrow":
        return <ArrowLeftCircle size={45} className="Wall__icon" />;
      case "search":
        return <Search size={45} className="Wall__icon" />;
      case "fail":
        return <Frown size={45} className="Wall__icon" />;
      case "file":
        return <File size={45} className="Wall__icon" />;
      case "select":
        return <UserPlus size={45} className="Wall__icon" />;
      case "null":
        return <FileMinus size={45} className="Wall__icon" />;
      default:
        return <></>;
    }
  };
  const classes = classNames(["Wall", className && className]);
  return (
    <div className={classes}>
      {Icon(icon)}
      <Text size="md" color="gray_700" className="Wall__msg">
        {value}
      </Text>
    </div>
  );
};
