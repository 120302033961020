import { client } from "data/axios";
import { QuoDataParticipantSummaryType } from "types/patientGroupListTypes";

export type ParticipantSummaryListType = {
  list: Array<ParticipantSummaryType>;
  nextPatientId: string;
  totalPatientCount: number;
};
export type ParticipantSummaryType = {
  id: string;
  carte: string;
  sex: number;
  birth: string;
  quoInfo: Array<QuoDataParticipantSummaryType>;
  answeredQuestionnaireCount: number;
  questionnaireDeliveriesCount:number;
}
/**
 * API名：getParticipantsSummary
 * 目的：全患者のデータを取得する処理
 * @param なし
 * @returns 患者の情報
 */
export async function getParticipantsSummary(
): Promise<
  ParticipantSummaryListType | undefined
> {
  try {
    const url = `/participants-summary`;
    const response = await client.get<ParticipantSummaryListType>(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
