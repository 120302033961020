import { useEffect } from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { useTabContext } from "components/tab-provider";

export const Error404 = () => {
  const { setHeaderContent } = useTabContext();
  useEffect(() => {
    document.title = "お探しのページは見つかりませんでした";
    setHeaderContent({
      mainHeader: "お探しのページは見つかりませんでした",
      subHeader: "",
    });
  }, []);
  return (
    <div className="Error404">
      <Text size="h1" color="gray_600">
        404 | Not found
      </Text>
      <Text size="h4" color="gray_600">
        お探しのページは見つかりませんでした
      </Text>
    </div>
  );
};
