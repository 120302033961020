import { postPatientGroupCheck } from "data/api/postPatientGroupCheck";
import {
  CheckPatientGroupData,
  PatientGroupDuplicateInformation,
} from "types/patientGroupListTypes";
import { setToast } from "ui/molecules/toast";
/**
 * PatientGroupChecker
 * 目的：患者グループ名の重複チェックとカルテIDが重複するか存在しないかの共通処理
 * @param patientGroupName
 * @param carteIdList
 * @returns response
 */
export const PatientGroupChecker = async (
  patientGroupName: string,
  carteIdList: string,
): Promise<PatientGroupDuplicateInformation | undefined> => {
  // 透明文字
  const INVISIBLE_CHARACTER = "‎ ";
  // 重複チェック結果: 重複
  const IS_DUPLICATE = "IS_DUPLICATE";
  // 重複グループ名重複なし結果：
  const IS_NOT_DUPLICATE = "";
  // カルテIDを改行する
  const checkDuplicateCarteId = carteIdList.split("\n");
  let duplicateInfo = "";
  let carteIdNotExistInfo = "";
  // 入力したカルテIDが重複確認
  let findDuplicates = (carteIdsList: string[]): string[] =>
    carteIdsList.filter((item, index) => carteIdsList.indexOf(item) !== index);
  const duplicateCarteId = findDuplicates(checkDuplicateCarteId);

  // 透明文字のチェック処理
  if (patientGroupName.includes(INVISIBLE_CHARACTER)) {
    setToast(
      "透明文字(Unicode:U+200e)を患者グループ名に使用しないでください。",
      false,
      5000,
    );
    return undefined;
  }
  //全角文字と半角数字のチェック処理
  if (patientGroupName.trim().length < 1) {
    setToast("患者グループ名を入力してください。", false, 5000);
    return undefined;
  }

  const checkData: CheckPatientGroupData = {
    patientGroupNameDuplicate: patientGroupName,
    carteIdNotExist: carteIdList.replaceAll("\n", ","),
  };
  const check = await postPatientGroupCheck(checkData);
  // 患者グループのカルテＩＤ重複チェック判定文
  if (duplicateCarteId.length > 0) {
    duplicateInfo =
      `以下カルテIDが重複しています。\n` +
      `${duplicateCarteId}\n` +
      `カルテIDを一意にしてください。\n\n`;
  } else {
    duplicateInfo = IS_NOT_DUPLICATE;
  }
  // 患者グループのカルテＩＤ存在チェック判定文
  if (carteIdList) {
    if (check?.resultCarteIdNotExist == IS_DUPLICATE) {
      carteIdNotExistInfo =
        `以下のカルテIDが存在しません。\n` +
        `${check?.carteIdNotExist}\n` +
        `存在しないカルテIDを削除してください。`;
    }
  } else {
    carteIdNotExistInfo = IS_NOT_DUPLICATE;
  }
  // 患者グループ名の重複チェック判定文
  if (check?.resultPatientGroupNameDuplicate == IS_DUPLICATE) {
    const response: PatientGroupDuplicateInformation = {
      duplicateInfo: duplicateInfo,
      carteIdNotExistInfo: carteIdNotExistInfo,
      duplicateGroupName: patientGroupName,
      isDuplicateGroupName: true,
    };
    return response;
  } else if (
    check?.resultCarteIdNotExist == IS_DUPLICATE ||
    duplicateCarteId.length > 0
  ) {
    // 患者グループ名が重複してない・カルテID重複・存在発生
    const response: PatientGroupDuplicateInformation = {
      duplicateInfo: duplicateInfo,
      carteIdNotExistInfo: carteIdNotExistInfo,
      duplicateGroupName: IS_NOT_DUPLICATE,
      isDuplicateGroupName: false,
    };
    return response;
  } else {
    // 正常の結果
    const response: PatientGroupDuplicateInformation = {
      duplicateInfo: IS_NOT_DUPLICATE,
      carteIdNotExistInfo: IS_NOT_DUPLICATE,
      duplicateGroupName: IS_NOT_DUPLICATE,
      isDuplicateGroupName: false,
    };
    return response;
  }
};
