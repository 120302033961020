import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import Logo from "assets/image/logo.svg";

import { Text } from "ui/atoms/text";
import { TextForm } from "ui/molecules/text-form";
import { LeadButton } from "ui/molecules/lead-button";
import { setToast } from "ui/molecules/toast";
import { PasswordForm } from "ui/molecules/password-form";
import { postDoctorRegistration } from "data/api/postDoctorRegistration";

export const SignUp = () => {
  useEffect(() => {
    document.title = "医師アカウント登録";
  }, []);
  const params = useParams();
  const navigation = useNavigate();
  const [email, setEmail] = useState(params.email ? params.email : ""); // params.emailがfalsyである場合は404エラーページに飛ぶのでこのページが呼び出されることはない
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const completion = () => {
    navigation("/signin");
    setToast("アカウント登録に成功しました", true);
  };
  const handleClick = async () => {
    if (!!!password1 || !!!password2) {
      setToast("パスワードを入力してください", false);
    } else if (password1 !== password2) {
      setToast("入力されたパスワードが一致していません", false);
    }
    if (password1 === password2 && !!password1) {
      const response = await postDoctorRegistration(email, password1);
      !!response
        ? completion()
        : setToast(
            "登録に失敗しました。無効なメールアドレスである可能性があります。",
            false,
          );
    }
  };
  return (
    <div className="signup">
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <Text size="h2" color="gray_800" className="signup__title">
        医師アカウント登録
      </Text>
      <Text size="lg" className="signup__comment">
        パスワードの登録を行います
      </Text>
      <TextForm
        type="email"
        value={email}
        placeholder="メールアドレス"
        onChange={setEmail}
        readOnly={true}
        // カラーの変更したい
      />
      <PasswordForm
        value={password1}
        placeholder="パスワード"
        onChange={setPassword1}
      />
      <PasswordForm
        value={password2}
        placeholder="パスワード（再入力）"
        onChange={setPassword2}
      />
      <LeadButton value="登録" onClick={handleClick} />
    </div>
  );
};
