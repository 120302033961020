import { useNavigate } from "react-router-dom";

import { QuestionnaireListType } from "types/types";
import { Card } from "ui/atoms/card";
import { setAnonymous, setType } from "ui/molecules/questionnaire-title";

export const QuestionnaireCard = ({
  data,
  path,
}: {
  data: QuestionnaireListType;
  path: string;
}) => {
  const navigation = useNavigate();
  const handleClick = () => {
    navigation(path + data.id);
  };

  return (
    <Card
      value={data.name}
      head={`${setType(data.type)}アンケート - ${setAnonymous(data.anonymous)}`}
      questionnaireStartDate={new Date(data.dataBuild!).toLocaleDateString()}
      questionnairePatientCount={data.answeredDataCount}
      questionnaireAnsweredCount={data.allDataCount}
      onClick={handleClick}
    />
  );
};
