import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

import { useTabContext } from "components/tab-provider";
import { Text } from "ui/atoms/text";
import { BuildBlock } from "ui/molecules/build-block";
import { getQuestionnaireList } from "data/api/getQuestionnaireList";
import {
  QuestionnaireListType,
  QuestionnaireUnImplementList,
} from "types/types";
import { getQuestionnaireUnImplementList } from "data/api/getQuestionnaireUnImplementList";
import { circleButton, CircleButton } from "ui/molecules/circle-button";
import { LoadingPage } from "components/loading-page";
import { Wall } from "ui/molecules/wall";
import { postQuestionnaireImplement } from "data/api/postQuestionnaireImplement";
import { setToast } from "ui/molecules/toast";
import { Modal } from "ui/molecules/modal";
import { TextArea } from "ui/molecules/text-area";
import { TextButton } from "ui/molecules/text-button";
import { ConfirmDialog } from "components/confirm-dialog";
import { LineSimulator, PostbackProps } from "components/line-simulator";
import { getQuestionnaireDetail } from "data/api/getQuestionnaireDetail";
import { useConfirmContext } from "components/confirm-dialog-provider";
import { deleteQuestionnaireDrop } from "data/api/deleteQuestionnaireDrop";
import { Dialog } from "components/dialog";
import { LoadingCircle } from "ui/molecules/loading-circle";
import { postQuestionnaireImplementForPatientGroup } from "data/api/postQuestionnaireImplementForPatientGroup";

export const QuestionnaireBundle = () => {
  const { setHeaderContent } = useTabContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [questionnaireList, setQuestionnaireList] = useState<
    Array<QuestionnaireListType>
  >([]);
  const [questionnaireUnImpList, setQuestionnaireUnImpList] = useState<
    Array<QuestionnaireUnImplementList>
  >([]);
  const success = (
    response: Array<QuestionnaireListType>,
    responseUnImp: Array<QuestionnaireUnImplementList>,
  ) => {
    setIsError(false);
    setQuestionnaireList(response);
    setQuestionnaireUnImpList(responseUnImp);
  };
  useEffect(() => {
    document.title = "アンケート管理";
    setHeaderContent({
      mainHeader: "アンケート管理",
      subHeader: "アンケートの情報・設定の編集を行います",
    });
    getList();
  }, []);
  const getList = async () => {
    setIsLoading(true);
    // 実施中/実施済アンケートのデータ取得
    const response = await getQuestionnaireList();
    // 実施前アンケートのデータ取得
    const responseUnImp = await getQuestionnaireUnImplementList();
    setIsLoading(false);
    !!response && !!responseUnImp
      ? success(response.reverse(), responseUnImp.reverse())
      : setIsError(true);
  };
  // アンケート実施APIエンドポイント周辺
  const [isOpen, setIsOpen] = useState(false);
  const [patientGroupId, setPatientGroupId] = useState<Array<number>>([]);
  const [implementId, setImplementId] = useState<number>();
  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <Wall value="アンケートデータの読み込みに失敗しました" icon="fail" />
  ) : (
    <div className="QuestionnaireBundle">
      {questionnaireUnImpList.length > 0 && (
        <div className="questionnaire-box">
          <Text size="h2" className="questionnaire-bundle-title">
            実施前アンケート
          </Text>
          {questionnaireUnImpList.map((questionnaire, index) => (
            <QuestionnaireBlock
              key={index}
              questionnaire={questionnaire}
              isImplement={false}
              setPatientGroupId={setPatientGroupId}
              setImplementId={setImplementId}
              setIsOpenImplement={setIsOpen}
              getList={getList}
            />
          ))}
        </div>
      )}
      {questionnaireList.length > 0 && (
        <div className="questionnaire-box">
          <Text size="h2" className="questionnaire-bundle-title">
            実施中/実施済アンケート
          </Text>
          {questionnaireList.map((questionnaire, index) => (
            <QuestionnaireBlock
              key={index}
              questionnaire={questionnaire}
              isImplement={true}
              setImplementId={setImplementId}
              setPatientGroupId={setPatientGroupId}
              setIsOpenImplement={setIsOpen}
              getList={getList}
            />
          ))}
        </div>
      )}
      <ImplementQuestionnaire
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        patientGroupId={patientGroupId}
        setPatientGroupId={setPatientGroupId}
        implementId={implementId}
        setImplementId={setImplementId}
        getList={getList}
        // setIsLoading={setIsLoading}
      />
    </div>
  );
};

const QuestionnaireBlock = ({
  questionnaire,
  isImplement,
  setImplementId,
  setPatientGroupId,
  setIsOpenImplement,
  getList,
}: {
  questionnaire: QuestionnaireListType;
  isImplement: boolean;
  setImplementId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPatientGroupId: React.Dispatch<React.SetStateAction<number[]>>;
  setIsOpenImplement: React.Dispatch<React.SetStateAction<boolean>>;
  getList: () => Promise<void>;
}) => {
  const navigation = useNavigate();
  const exp = (value: number) => {
    switch (value) {
      case 0:
        return "問診型アンケート";
      case 1:
        return "匿名型アンケート";
      default:
        return "";
    }
  };

  const status = (value: number) => {
    switch (value) {
      case 0:
        return "登録時実施";
      case 1:
        return "不定期アンケート配信済";
      // case 2:
      //   return "定期アンケート実施中";
      // case 3:
      //   return "定期アンケート終了済";
      default:
        return "";
    }
  };
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  const deleteQuestionnaire = () => {
    setConfirmDialog({
      title: "アンケートを削除してよろしいですか？",
      text: [
        `アンケート名：${questionnaire.name}`,
        `アンケート形式：${exp(questionnaire.anonymous)}`,
      ],
      handleClick: async () => {
        const response = await deleteQuestionnaireDrop(
          questionnaire.id.toString(),
        );
        response
          ? successDelete()
          : setToast("アンケートの削除が正常に行われませんでした", false);
      },
    });
    setIsOpen(true);
  };
  const successDelete = () => {
    setToast("アンケートが削除されました", true);
    getList();
  };

  // アンケート複製処理
  const copyQuestionnaire = async () => {
    const response = await getQuestionnaireDetail(questionnaire.id.toString());
    // 新規アンケート作成画面へ遷移する
    navigation(`/questionnaire-build`, { state: response });
  };

  return (
    <BuildBlock
      title={questionnaire.name}
      exp={exp(questionnaire.anonymous)}
      className="QuestionnaireBlock"
    >
      <div>
        <Text color={"gray_700"}>アンケートの状態：　</Text>
        <Text color={"black"}>
          {questionnaire.status !== undefined
            ? status(questionnaire.status)
            : "配信前"}
        </Text>
      </div>
      <div>
        <Text color={"gray_700"}>患者IDグループ：　</Text>
        <Text color={"black"}>
          {questionnaire.patientGroupName == ""
            ? "-"
            : questionnaire.patientGroupName}
        </Text>
      </div>
      {questionnaire.dataBuild !== undefined && (
        <div>
          <Text color={"gray_700"}>アンケート実施日：　</Text>
          <Text color={"black"}>
            {new Date(questionnaire.dataBuild).toLocaleDateString()}
          </Text>
        </div>
      )}
      {questionnaire.dataUpdate !== undefined && (
        <div>
          <Text color={"gray_700"}>アンケート更新日：　</Text>
          <Text color={"black"}>
            {new Date(questionnaire.dataUpdate).toLocaleDateString()}
          </Text>
        </div>
      )}
      <div className="bundle-buttons">
        {!isImplement && (
          <CircleButton
            type={circleButton.edit}
            onClick={() => {
              navigation(`/questionnaire-bundle/${questionnaire.id}`);
            }}
            tooltip={"編集"}
          />
        )}
        {!isImplement && (
          <CircleButton
            type={circleButton.trash}
            onClick={deleteQuestionnaire}
            className={"send-button"}
            tooltip={"削除"}
          />
        )}
        {!isImplement && (
          <CircleButton
            type={circleButton.send}
            onClick={() => {
              setImplementId(questionnaire.id);
              setPatientGroupId(questionnaire.patientGroupId);
              setIsOpenImplement(true);
            }}
            className={"send-button"}
            tooltip={"アンケートを配信します"}
          />
        )}
        <CircleButton
          type={circleButton.copy}
          onClick={() => {
            copyQuestionnaire();
          }}
          className={"send-button"}
          tooltip={"アンケートの複製"}
        />
      </div>
    </BuildBlock>
  );
};

const ImplementQuestionnaire = ({
  isOpen,
  setIsOpen,
  patientGroupId,
  setPatientGroupId,
  implementId,
  setImplementId,
  getList,
}: // setIsLoading,
{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  patientGroupId: Array<number>;
  setPatientGroupId: React.Dispatch<React.SetStateAction<number[]>>;
  implementId: number | undefined;
  setImplementId: React.Dispatch<React.SetStateAction<number | undefined>>;
  getList: () => Promise<void>;
  // setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<
    Array<Array<string> | PostbackProps>
  >([[message]]);
  const [firstQuestion, setFirstQuestion] = useState<{
    property: number;
    text: string;
    choices: Array<string>;
  }>();
  useEffect(() => {
    !isOpen &&
      (() => {
        setPatientGroupId([]);
        setImplementId(undefined);
        setMessage("");
      })();
  }, [isOpen]);
  const [isLoading, setIsLoading] = useState(false);
  const placeholder =
    "例：こんにちは！ 現在の病状について、以下のアンケートへのご回答をよろしくお願いします。";

  // 配信ダイアログ
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const handleClick = () => {
    setIsOpenConfirm(true);
  };
  useEffect(() => {
    (async () => {
      const response = !!implementId
        ? await getQuestionnaireDetail(implementId.toString())
        : undefined;
      !!response
        ? setFirstQuestion({
            property: response.questions[0].property,
            text: response.questions[0].text,
            choices: response.questions[0].choices.map((choice) => choice.text),
          })
        : setFirstQuestion(undefined);
      !!response &&
        setDescryptQuestion(
          response.questions[0].property,
          response.questions[0].text,
        );
    })();
  }, [implementId]);
  useEffect(() => {
    !!firstQuestion &&
      setDescryptQuestion(firstQuestion.property, firstQuestion.text);
  }, [message]);
  const startMessage: PostbackProps = {
    title: "アンケートを始めます",
    exp: "準備ができたら下の「始める」ボタンを押してください",
    choices: ["始める"],
  };
  const setDescryptQuestion = (property: number, text: string) => {
    property === 1 &&
      setMessages([
        [message],
        startMessage,
        ["アンケートを始めます"],
        [text, "（アンケートはまだ終了していません！）"],
      ]);
    property === 2 &&
      setMessages([
        [message],
        startMessage,
        ["アンケートを始めます"],
        [
          text,
          "半角数字のみを入力して送信してください！",
          "（アンケートはまだ終了していません！）",
        ],
      ]);
  };
  // 配信ギミック
  const implementQuestionnaire = (
    patientGroupId: Array<number>,
    id: number,
    message: string,
  ) => {
    (async () => {
      setIsLoading(true);
      let response;
      if (patientGroupId.length == 0) {
        response = await postQuestionnaireImplement(id, message);
      } else {
        response = await postQuestionnaireImplementForPatientGroup(
          patientGroupId,
          id,
          message,
        );
      }
      !!response
        ? successImplement(response.name, response.cartes)
        : failureImplement();
      setIsLoading(false);
    })();
  };
  const failureImplement = () => {
    setToast("エラーが発生したためアンケートを開始できませんでした", false);
  };
  // 半角スペースか全角スペースを入力するチェック定数
  const QUESTIONNAIRE_SPACE_INPUT_CHECKER = message.trim().length < 1
  // 配信後のリザルト表示
  const [isOpenResult, setIsOpenResult] = useState(false);
  const [resultTitle, setResultTitle] = useState<string>();
  const [resultCarteIds, setResultCarteIds] = useState<Array<string>>();
  const successImplement = (name: string, cartes: Array<string>) => {
    setToast("アンケートが開始されました", true);
    setResultTitle(name);
    setResultCarteIds(cartes);
    setIsOpen(false);
    setIsOpenResult(true);
  };

  return (
    <div className={"ImplementQuestionnaire"}>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <LoadingCircle />
        ) : (
          <div className="implement-start-message">
            <Text size="h2" className="i-s-m-title">
              アンケートの実施
            </Text>
            <Text size="lg" color={"gray_700"} className="i-s-m-exp">
              アンケート開始時に送信されるメッセージを設定してください。
            </Text>
            <div className="line-simulate-box">
              <LineSimulator
                messages={
                  firstQuestion?.property === 0
                    ? [
                        [message],
                        startMessage,
                        ["アンケートを始めます"],
                        {
                          title: firstQuestion.text,
                          exp: "以下の選択肢からひとつ選んでください",
                          choices: firstQuestion.choices,
                        },
                      ]
                    : messages
                }
              />
              <TextArea
                value={message}
                className={"dm-textarea"}
                rows={20}
                handle={(value: string) => {
                  setMessage(value);
                }}
                placeholder={placeholder}
              />
            </div>
            <div className={"i-s-m-button"}>
              <TextButton
                value={
                  QUESTIONNAIRE_SPACE_INPUT_CHECKER
                    ? "メッセージを入力してください"
                    : "アンケートを配信する"
                }
                onClick={handleClick}
                type={"black"}
                disable={QUESTIONNAIRE_SPACE_INPUT_CHECKER}
              />
            </div>
          </div>
        )}
      </Modal>
      <ConfirmDialog
        title={"アンケートの配信を行います"}
        text={[
          "LINE登録している患者全員にアンケートが送信され、アンケートの集計が開始されます。",
          <Text
            size="md"
            color={"danger"}
            className="questionnaire-bundle-alert"
          >
            ⚠️ この操作は取り消せません ⚠️
          </Text>,
        ]}
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        handleClick={async () => {
          !!implementId
            ? implementQuestionnaire(patientGroupId!, implementId, message)
            : setToast("エラーが発生したため処理が中断されました", false);
        }}
      />
      <Dialog
        title={`アンケート【${resultTitle}】の配信が行われました`}
        text={[
          `正常にアンケートの配信が行われた対象者は以下${resultCarteIds?.length}名です`,
          <div>
            {resultCarteIds?.map((data, index) => (
              <span key={index}>
                {index !== 0 && <br />}
                {data}
              </span>
            ))}
          </div>,
        ]}
        isOpen={isOpenResult}
        setIsOpen={() => {
          setIsOpenResult(false);
          getList();
        }}
        buttonValue={"閉じる"}
        handleClick={() => {
          setIsOpenResult(false);
          getList();
        }}
      />
    </div>
  );
};
