import classNames from "classnames";
import { useState } from "react";

import "./index.scss";

export const DropDown = ({
  value,
  options,
  onChange,
  className,
  disable = false,
}: {
  value: number;
  options: Array<string>;
  onChange: (option: number) => void;
  className?: string;
  disable?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };
  const classes = classNames(["DropDown", className, disable && "disable"]);

  const controlClassesName = classNames([
    "control",
    disable && "control_disable",
  ]);

  return (
    <div className={classes}>
      <div
        className={controlClassesName}
        onClick={!disable ? handleClick : () => {}}
      >
        {options[value]}
      </div>
      {isOpen && disable == false && (
        <div className="menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => {
                onChange(index);
                setIsOpen(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
