import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";

import "./index.scss";

import { Input } from "ui/atoms/input";

export const PasswordForm = ({
  value,
  placeholder = "パスワード",
  onChange,
  onKeyDown,
}: {
  value: string;
  placeholder?: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  onKeyDown?: () => void;
}) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };
  return (
    <div className="PasswordForm">
      <Input
        type={isRevealPassword ? "text" : "password"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <span onClick={togglePassword} role="presentation">
        {isRevealPassword ? <Eye /> : <EyeOff />}
      </span>
    </div>
  );
};
