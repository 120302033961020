import React, { ReactNode, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import "./index.scss";

import { Header } from "components/layout/header";
import { Sidebar } from "./sidebar";
import classNames from "classnames";

type LayoutContext = {
  sidebar: boolean;
  setSidebar: (isOpen: boolean) => void;
};

const LayoutContext = React.createContext<LayoutContext>({
  sidebar: true,
  setSidebar: () => {
    console.log("setSidebar unimplemented");
  },
});

export const Layout = () => {
  const [sidebar, setSidebar] = useState(true);
  return (
    <LayoutContext.Provider
      value={{
        sidebar,
        setSidebar,
      }}
    >
      <div className="Layout">
        <Sidebar />
        <Pagebox>
          <Outlet />
        </Pagebox>
      </div>
    </LayoutContext.Provider>
  );
};

const Pagebox = ({ children }: { children: ReactNode }) => {
  const layout = useLayoutContext();
  const classes = classNames("Pagebox", layout.sidebar && "sidebarActive");
  return (
    <div className={classes}>
      <Header />
      <div className="pageview">{children}</div>
    </div>
  );
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
