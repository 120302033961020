import { useEffect } from "react";

import { useTabContext } from "components/tab-provider";
import { Wall } from "ui/molecules/wall";

export const Home = ({ tabIndex }: { tabIndex?: number }) => {
  const { setHeaderContent } = useTabContext();
  useEffect(() => {
    document.title = "トップページ";
    setHeaderContent({
      mainHeader: "トップページ",
      subHeader: "",
    });
  }, []);
  return (
    <Wall
      value="左メニューからご利用になる項目を選択してください"
      icon="arrow"
    />
  );
};
