import { client } from "data/axios";
import { UpdatePatientGroupEditor } from "types/patientGroupListTypes";

/**
 * API名：patientGroupUpdate
 * 目的：患者グループの情報を更新するAPI
 * @param data 
 * @returns 
 */
export async function putPatientGroupUpdate(
  data: UpdatePatientGroupEditor
): Promise<boolean | undefined> {
  try {
    const url = "/patient-group/update";
    const response = await client.put(url, data);

    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
