import { useEffect, useState } from "react";

import "./index.scss";

import {
  DeletePatientGroup,
  PatientGroupListType,
} from "types/patientGroupListTypes";
import { getPatientGroupList } from "data/api/getPatientGroupList";
import { useTabContext } from "components/tab-provider";
import { LoadingPage } from "components/loading-page";
import { Wall } from "ui/molecules/wall";
import { PatientGroupListRegister } from "components/patient-group-list-register";
import { TextButton, textButtonIcon } from "ui/molecules/text-button";
import { Container } from "ui/atoms/container";
import { CircleButton, circleButton } from "ui/molecules/circle-button";
import { Text } from "ui/atoms/text";
import { PatientGroupListEditBox } from "components/patient-group-list-update";
import { useConfirmContext } from "components/confirm-dialog-provider";
import { putPatientGroupDelete } from "data/api/putPatientGroupDelete";
import { setToast } from "ui/molecules/toast";

export const PatientGroupList = () => {
  const { setHeaderContent } = useTabContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patientGroupList, setPatientGroupList] = useState<
    Array<PatientGroupListType>
  >([]);
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [patientGroupName, setPatientGroupName] = useState("");
  const [carteIdList, setCarteIdList] = useState("");
  const [patientNote, setPatientNote] = useState("");
  const [hasPatientGroupList, setHasPatientGroupList] = useState(false);
  const [patientGroupCount, setPatientGroupCount] = useState(0);
  const handleClickRegister = () => {
    setIsOpenEditor(true);
  };
  const success = (response: Array<PatientGroupListType>) => {
    setIsError(false);
    setPatientGroupList(response);
    setPatientGroupCount(response.length);
    setHasPatientGroupList(response.length > 0);
  };

  useEffect(() => {
    document.title = "患者グループ一覧";
    setHeaderContent({
      mainHeader: "患者グループ一覧",
      subHeader: "患者グループ一覧です。",
    });
    getGroupList();
  }, []);

  const getGroupList = async () => {
    setIsLoading(true);
    const response = await getPatientGroupList();
    setIsLoading(false);
    !!response ? success(response) : setIsError(true);
  };

  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <Wall value="患者グループの取得に失敗しました" icon="fail" />
  ) : patientGroupCount == 0 ? (
    <div className="PatientGroupList">
      <Wall
        value="患者グループはまだ作成されていません"
        className="NoPatientGroups"
        icon="null"
      />
      <div className="registerButtonParent">
        <TextButton
          className="registerButton"
          value="グループを作成する"
          icon={textButtonIcon.plus}
          onClick={handleClickRegister}
        />
        <PatientGroupListRegister
          patientGroupName={patientGroupName}
          setPatientGroupName={setPatientGroupName}
          patientNote={patientNote}
          setPatientNote={setPatientNote}
          carteIdList={carteIdList}
          setCarteIdList={setCarteIdList}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </div>
    </div>
  ) : hasPatientGroupList ? (
    <div className="PatientGroupList">
      {patientGroupList.map((data, index) => (
        <span key={index}>
          <PatientGroupCard data={data} getGroupList={getGroupList} />
        </span>
      ))}
      <div className="registerButtonParent">
        <TextButton
          className="registerButton"
          value="グループを作成する"
          icon={textButtonIcon.plus}
          onClick={handleClickRegister}
        />
        <PatientGroupListRegister
          patientGroupName={patientGroupName}
          setPatientGroupName={setPatientGroupName}
          patientNote={patientNote}
          setPatientNote={setPatientNote}
          carteIdList={carteIdList}
          setCarteIdList={setCarteIdList}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </div>
    </div>
  ) : (
    <div className="registerButtonParent">
      <TextButton
        className="registerButton"
        value="グループを作成する"
        icon={textButtonIcon.plus}
        onClick={handleClickRegister}
      />
      <PatientGroupListRegister
        patientGroupName={patientGroupName}
        setPatientGroupName={setPatientGroupName}
        patientNote={patientNote}
        setPatientNote={setPatientNote}
        carteIdList={carteIdList}
        setCarteIdList={setCarteIdList}
        isOpen={isOpenEditor}
        setIsOpen={setIsOpenEditor}
      />
    </div>
  );
};

// 一覧データ部分
const PatientGroupCard = ({
  data,
  getGroupList,
}: {
  data: PatientGroupListType;
  getGroupList: () => Promise<void>;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  
  // 患者グループ削除APIのレスポンス結果定数
  const PATIENT_GROUP_DELETE_RESULT_OK = "CAN_DELETE";
  const PATIENT_GROUP_DELETE_RESULT_NG = "CANNOT_DELETE";

  const handleClickTrash = () => {
    const deletePatientGroupData: DeletePatientGroup = {
      patientGroupId: data.patientGroupId,
    };
    setConfirmDialog({
      title: "患者グループを削除してよろしいですか？",
      text: [`患者グループ名：${data.patientGroupName}`],
      handleClick: async () => {
        const response = await putPatientGroupDelete(deletePatientGroupData);
        // 患者グループがアンケートに紐づいていない場合の表示処理
        if(response?.result == PATIENT_GROUP_DELETE_RESULT_OK){
          successDelete();
        }else if(response?.result == PATIENT_GROUP_DELETE_RESULT_NG){
          // 患者グループがアンケートに紐づいている場合の表示処理
          successCannotDelete();
        }else{
          setToast(data.patientGroupName + "の削除が失敗しました。", false);
        }
      }
    });
    setIsOpen(true);
  };
  // 患者グループは削除された表示処理
  const successDelete = () => {
    setToast(data.patientGroupName + "を削除しました",true, 2000);
    getGroupList();
  };
  // 患者グループは削除されていない表示処理
  const successCannotDelete = () => {
    setToast(data.patientGroupName + "はアンケートに利用されているため、削除できません。", false,2000);
  };
  
  const handleClickEdit = () => {
    setIsOpenEditor(true);
  };
  return (
    <Container className="PatientEditor">
      <div>
        <Text size="h1" color="gray_800" className="patient-editor-title">
          {data.patientGroupName}
        </Text>
      </div>
      <div>
        <Text>人数： {data.patientCount}</Text>
      </div>
      <div>
        <Text>備考： {data.patientGroupInformation}</Text>
      </div>
      <div>
        <Text>作成日時：{new Date(data.dataBuild).toLocaleDateString()}</Text>
      </div>
      <div>
        <Text>
          最終更新日時：{new Date(data.dataUpdate).toLocaleDateString()}
        </Text>
      </div>
      <div className="buttons">
        <div className={"circle-button-edit"}>
          <CircleButton type={circleButton.edit} onClick={handleClickEdit} />
        </div>
        <div className={"circle-button-trash"}>
          <CircleButton type={circleButton.trash} onClick={handleClickTrash} />
        </div>
        <PatientGroupListEditBox
          patientGroupId={data.patientGroupId}
          patientGroupName={data.patientGroupName}
          patientGroupInformation={data.patientGroupInformation}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </div>
    </Container>
  );
};
