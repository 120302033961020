import classNames from "classnames";
import { CheckSquare, Square } from "react-feather";
import "./index.scss";

export const CheckBox = ({
  isChecked,
  className,
}: {
  isChecked: boolean;
  className?: string;
}) => {
  const classes = classNames(["CheckBox", className && className]);
  return isChecked ? (
    <CheckSquare className={classes} />
  ) : (
    <Square className={classes} />
  );
};
