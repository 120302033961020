import { useEffect, useState } from "react";

import "./index.scss";

import { Container } from "ui/atoms/container";
import { LoadingPage } from "components/loading-page";
import { useTabContext } from "components/tab-provider";
import { Note } from "ui/molecules/note";
import { Wall } from "ui/molecules/wall";
import { PersonProfile } from "ui/molecules/person-profile";
import {
  getParticipantsSummary,
  ParticipantSummaryListType,
  ParticipantSummaryType,
} from "data/api/getParticipantsSummary";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { useNavigate } from "react-router-dom";
import { TextBox } from "ui/molecules/text-box";
import { TextButton } from "ui/molecules/text-button";
import { CheckBox } from "ui/atoms/check-box";
import classNames from "classnames";
import { Text } from "ui/atoms/text";
import { setToast } from "ui/molecules/toast";
import { Modal } from "ui/molecules/modal";
import { TextArea } from "ui/molecules/text-area";
import { ConfirmDialog } from "components/confirm-dialog";
import { postSendLineDirectMessage } from "data/api/postSendLineDirectMessage";
import { getParticipantsRequiredLine } from "data/api/getParticipantsRequiredLine";
import { LineSimulator } from "components/line-simulator";
import { useAuthContext } from "components/auth-provider";
import { Center } from "ui/atoms/center";
import { Loader } from "react-feather";
import { Dialog } from "components/dialog";
import { DropDown } from "ui/molecules/drop-down";
import { Button } from "@mui/material";

export const Participant = () => {
  const { setHeaderContent } = useTabContext();
  const { user } = useAuthContext();
  const [isError, setIsError] = useState(false);
  const [participantsSummary, setParticipantsSummary] = useState<
    Array<ParticipantSummaryType>
  >([]);
  const [searchParticipants, setSearchParticipants] = useState<
    Array<ParticipantSummaryType>
  >([]);
  const [requiredLineList, setRequiredLineList] = useState<Array<string>>([]);

  // 表示されているテキストの数の定数
  const [currentPatientCount, setCurrentPatientCount] = useState("");

  const success = (response: ParticipantSummaryListType) => {
    const participantList = response.list;
    const currentCount = response.list.length;
    // 表示されている患者データの数を設定
    if(currentCount > 0){
      setCurrentPatientCount(currentCount.toString());
    }else{
      setCurrentPatientCount("-");
    }
    setIsError(false);
    setParticipantsSummary(participantList);
    setSearchParticipants(participantList);
  };
  // LINE再登録リストを除く
  const successLineList = (response: Array<string>) => {
    setRequiredLineList(response);
  };
  useEffect(() => {
    document.title = "患者データ";
    setHeaderContent({
      mainHeader: "患者データ",
      subHeader: "",
    });
    (async () => {
      setIsLoading(true);
      const response = await getParticipantsSummary();
      setIsLoading(false);
      
      !!response ? success(response) : setIsError(true);
    })();

    // LINE再登録が必要なリスト取得
    (async () => {
      const responseLineList = await getParticipantsRequiredLine();
      !!responseLineList && successLineList(responseLineList);
    })();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [carteId, setCarteId] = useState("");
  // 年度選択
  const [selectedYear, setSelectedYear] = useState(0);
  // 状況選択
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [isNote, setIsNote] = useState(false);
  // 検索ボックスの値が書き換えられる度に呼び出される
  const exp = "^[0-9]{1,8}$";
  const [status, setStatus] = useState(true);

  // 年度の定数
  const SELECTED_NO_YEAR: number = 0;
  const SELECTED_LAST_YEAR: number = 1;
  const SELECTED_THIS_YEAR: number = 2;

  // 状況の定数
  const STATUS_NO_SELECTED: number = 0;

  // 年度
  const YEAR = "年度";
  const LAST_YEAR = "昨年度";
  const THIS_YEAR = "今年度";

  // 状況
  const NO_STATUS = "状況";
  const NOT_PROVIDED_STATUS = "未提供";
  const PROVIDED_STATUS = "提供済み";
  const NOT_COVERED_STATUS = "対象外";
  const OTHER_STATUS = "その他";

  // 全角文字を半角に変換する定数
  const CHANGE_TO_HALF_WIDTH = 0xfee0;

  // 昨年度と状況を選択する場合の処理
  const lastQuoYearStatusSettingWithCarteId = (
    quoValue: number,
    carteId: string,
  ) => {
    setIsNote(false);
    setIsLoading(true);
    const searchCriteria: Array<ParticipantSummaryType> = [];
    participantsSummary.filter((participant) => {
      participant.carte.indexOf(carteId) > -1 &&
        participant.quoInfo[0].quoStatus == quoValue &&
        searchCriteria.push(participant);
    });
    setCurrentPatientCount(searchCriteria.length.toString());
    setSearchParticipants(searchCriteria);
    setIsLoading(false);
  };

  // 今年度と状況を選択する場合の処理
  const thisQuoYearStatusSettingWithCarteId = (
    quoValue: number,
    carteId: string,
  ) => {
    setIsNote(false);
    setIsLoading(true);
    const searchCriteria: Array<ParticipantSummaryType> = [];
    participantsSummary.filter((participant) => {
      participant.carte.indexOf(carteId) > -1 &&
        participant.quoInfo[1].quoStatus == quoValue &&
        searchCriteria.push(participant);
    });
    setCurrentPatientCount(searchCriteria.length.toString());
    setSearchParticipants(searchCriteria);
    setIsLoading(false);
  };

  // 検索ボックスの全角チェック->半角
  const handleClick = (carteId: string, status: number, year: number) => {
    if (!carteId) {
      setCarteId("");
    }
    // 入力されたカルテIDが①空欄②8桁以下③その他の３ケースに場合分け
    if (carteId === "") {
      if (year == SELECTED_NO_YEAR) {
        if (status == STATUS_NO_SELECTED) {
          setIsNote(false);
          setSearchParticipants(participantsSummary);
          setCurrentPatientCount(participantsSummary.length.toString());
        }
      } else if (year == SELECTED_LAST_YEAR) {
        // 年度：「昨年度」を選択した場合
        if (status == STATUS_NO_SELECTED) {
          setIsNote(false);
          setSearchParticipants(participantsSummary);
          setCurrentPatientCount(participantsSummary.length.toString());
        } else {
          lastQuoYearStatusSettingWithCarteId(status, carteId);
        }
      } else if (year == SELECTED_THIS_YEAR) {
        // 年度：「今年度」を選択した場合
        if (status == STATUS_NO_SELECTED) {
          setIsNote(false);
          setSearchParticipants(participantsSummary);
          setCurrentPatientCount(participantsSummary.length.toString());
          // 状況： 「状況」以外を選択した場合
        } else {
          thisQuoYearStatusSettingWithCarteId(status, carteId);
        }
      }
    } else if (carteId.match(exp)) {
      if (year == SELECTED_NO_YEAR) {
        setIsNote(false);
        (async () => {
          setIsLoading(true);
          const searchCriteria: Array<ParticipantSummaryType> = [];
          participantsSummary.filter((participant) => {
            participant.carte.indexOf(carteId) > -1 &&
              searchCriteria.push(participant);
          });
          setSearchParticipants(searchCriteria);
          setCurrentPatientCount(searchCriteria.length.toString());
          setIsLoading(false);
        })();
      } else if (year == SELECTED_LAST_YEAR) {
        // 年度：「昨年度」を選択した場合
        // 状況：「状況」を選択した場合
        if (status == STATUS_NO_SELECTED) {
          setIsNote(false);
          setIsLoading(true);
          const searchCriteria: Array<ParticipantSummaryType> = [];
          participantsSummary.filter((participant) => {
            participant.carte.indexOf(carteId) > -1 &&
              searchCriteria.push(participant);
          });
          setSearchParticipants(searchCriteria);
          setCurrentPatientCount(searchCriteria.length.toString());
          setIsLoading(false);
        } else {
          lastQuoYearStatusSettingWithCarteId(status, carteId);
        }
      } else if (year == SELECTED_THIS_YEAR) {
        // 年度：「今年度」を選択した場合
        // 状況：「状況」を選択した場合
        if (status == STATUS_NO_SELECTED) {
          setIsNote(false);
          setIsLoading(true);
          const searchCriteria: Array<ParticipantSummaryType> = [];
          participantsSummary.filter((participant) => {
            participant.carte.indexOf(carteId) > -1 &&
              searchCriteria.push(participant);
          });
          setSearchParticipants(searchCriteria);
          setCurrentPatientCount(searchCriteria.length.toString());
          setIsLoading(false);
        } else {
          thisQuoYearStatusSettingWithCarteId(status, carteId);
        }
      }
    }else {
      setIsNote(true);
      setSearchParticipants([]);
      return String(status).replace(/[！-～]/g, function (all) {
        setToast("半角で入力して下さい");
        return String.fromCharCode(all.charCodeAt(0) - CHANGE_TO_HALF_WIDTH);
      });
    }
  };
  // 入力したカルテIDの値をセットする処理（inputValueとは入力した値）
  const inputCarteId = (selectedCarteId: string) => {
    setCarteId(selectedCarteId);
  };
  // 選択した状況の値をセットする処理（inputValueとは選択した状況）
  const inputStatus = (selectedStatus: number) => {
    setSelectedStatus(selectedStatus);
  };
  // 選択した年度の値をセットする処理（inputValueとは選択した年度）
  const inputYear = (selectedYear: number) => {
    setSelectedYear(selectedYear);
    if (
      selectedYear == SELECTED_LAST_YEAR ||
      selectedYear == SELECTED_THIS_YEAR
    ) {
      setStatus(false);
    } else if (selectedYear == SELECTED_NO_YEAR) {
      setStatus(true);
      setSelectedStatus(0);
    }
  };
  // 任意の患者複数にLINEを送るAPIのエンドポイント
  const [isSelectMode, setIsSelectMode] = useState(false);
  const handleClickSelectButton = () => {
    setSelectedList([]);
    setIsSelectMode(!isSelectMode ? true : false);
  };
  const [selectedList, setSelectedList] = useState<
    Array<ParticipantSummaryType>
  >([]);
  const [isOpenLineDM, setIsOpenLineDM] = useState(false);
  return (
    <div className="Participant">
      {isLoading ? (
        <LoadingPage />
      ) : !!isError ? (
        <Wall value="患者データの取得に失敗しました" icon="fail" />
      ) : (
        <>
          <TextBox
            value={carteId}
            isSearchBox={true}
            placeholder="カルテID下8桁を入力してください"
            handle={inputCarteId}
          />
          <DropDown
            value={selectedYear}
            options={[YEAR, LAST_YEAR, THIS_YEAR]}
            className={"index-container"}
            onChange={inputYear}
          />
          <DropDown
            value={selectedStatus}
            options={[
              NO_STATUS,
              NOT_PROVIDED_STATUS,
              PROVIDED_STATUS,
              NOT_COVERED_STATUS,
              OTHER_STATUS,
            ]}
            className={"value-container"}
            onChange={inputStatus}
            disable={status}
          />
          <Button
            variant="outlined"
            className="search-button"
            sx={{
              color: "#4a4a4a",
              backgroundColor: "#ffffff",
              borderColor: "#afafaf",
              "&:hover": {
                backgroundColor: "#dddddd",
                color: "#4a4a4a",
                borderColor: "#afafaf",
              },
            }}
            onClick={(e) => handleClick(carteId, selectedStatus, selectedYear)}
          >
            検索
          </Button>
          <Text
            className="patient-count-text"
          >
            {"患者数：" +
              currentPatientCount 
            }
          </Text>
          <Note
            value="カルテID（下8桁）を正しく入力してください"
            isNote={isNote}
          />
          {user?.admin && (
            <TextButton
              value={!isSelectMode ? "選択" : "キャンセル"}
              onClick={handleClickSelectButton}
              className={"select-button"}
              isStatus={isSelectMode}
            />
          )}
          <div className="participant-content">
            {isSelectMode && (
              <SelectedParticipantList
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            )}
            {searchParticipants.length === 0  ? (
              <Wall
                value="該当する患者データが見つかりませんでした"
                icon="search"
              />
            ) : 
            (
              <InternalScroll className="participants-list">
                {searchParticipants.filter(
                  (participant) =>
                    !isSelectMode || !requiredLineList.includes(participant.id),
                ).length > 0 ? (
                  <div>
                    {searchParticipants.map((participant, index) => (
                      <ParticipantUnit
                        key={index}
                        participant={participant}
                        isSelectMode={isSelectMode}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                        isRequiredLine={requiredLineList.includes(
                          participant.id,
                        )}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={"no-valid-line-data"}>
                    <Wall
                      value={
                        "有効なLINE IDが登録されている患者データがありません"
                      }
                      icon={"null"}
                    />
                  </div>
                )}
              </InternalScroll>
            )}
          </div>
          {isSelectMode && (
            <div className="bottom-button-bar">
              <div>
                <TextButton
                  value={"全選択"}
                  onClick={() => {
                    setSelectedList(
                      searchParticipants.filter(
                        (participant) =>
                          !requiredLineList.includes(participant.id) &&
                          participant,
                      ),
                    );
                  }}
                  disable={
                    selectedList.length ===
                      searchParticipants.length - requiredLineList.length &&
                    true
                  }
                  className={"all-select"}
                />
                <TextButton
                  value={"全解除"}
                  onClick={() => {
                    setSelectedList([]);
                  }}
                  disable={selectedList.length < 1 && true}
                  className={"all-deselect"}
                />
              </div>
              <TextButton
                value="LINEダイレクトメッセージを送信する"
                onClick={() => {
                  selectedList.length > 0 && setIsOpenLineDM(true);
                }}
                disable={selectedList.length < 1 && true}
                className={"send-line-dm"}
              />
            </div>
          )}
        </>
      )}
      <SendLineDirectMessage
        selectedList={selectedList}
        isOpen={isOpenLineDM}
        setIsOpen={setIsOpenLineDM}
      />
    </div>
  );
};

const ParticipantUnit = ({
  participant,
  isSelectMode,
  selectedList,
  setSelectedList,
  isRequiredLine,
}: {
  participant: ParticipantSummaryType;
  isSelectMode: boolean;
  selectedList: Array<ParticipantSummaryType>;
  setSelectedList: (selectedList: Array<ParticipantSummaryType>) => void;
  isRequiredLine: boolean;
}) => {
  const navigation = useNavigate();
  const [isSelect, setIsSelect] = useState(false);
  const handleClick = async () => {
    !isSelectMode
      ? !isRequiredLine
        ? navigation(`/participant/${participant.carte}`)
        : navigation(`/participant/line-registration/${participant.carte}`)
      : !isSelect
        ? selectPatient()
        : deselectPatient();
  };
  // 選択した患者処理
  const selectPatient = () => {
    setSelectedList([...selectedList, participant]);
  };
  // 選択を外す処理
  const deselectPatient = () => {
    setSelectedList(selectedList.filter((select) => select !== participant));
  };
  useEffect(() => {
    setIsSelect(selectedList.includes(participant));
  }, [selectedList]);
  const classes = classNames(["participant-container", isSelect && "selected"]);
  return isSelectMode && isRequiredLine ? (
    <div></div>
  ) : (
    <Container className={classes} onClick={handleClick}>
      {isSelectMode && (
        <CheckBox isChecked={isSelect} className={"patient-checkbox"} />
      )}
      <PersonProfile
        sex={participant.sex}
        carte={participant.carte}
        birth={participant.birth}
        lastQuoYear={participant.quoInfo[0].quoYear}
        thisQuoYear={participant.quoInfo[1].quoYear}
        lastYearQuoStatus={participant.quoInfo[0].quoStatus}
        thisYearQuoStatus={participant.quoInfo[1].quoStatus}
        answeredQuestionnaireCount={participant.answeredQuestionnaireCount}
        questionnaireDeliveriesCount={participant.questionnaireDeliveriesCount}
      />
      {isRequiredLine && (
        <Text className="alert">⚠️ LINE-IDの再登録をお願いします ⚠️</Text>
      )}
    </Container>
  );
};

const SelectedParticipantList = ({
  selectedList,
  setSelectedList,
}: {
  selectedList: Array<ParticipantSummaryType>;
  setSelectedList: (selectedList: Array<ParticipantSummaryType>) => void;
}) => {
  const SelectedParticipantUnit = ({
    participant,
  }: {
    participant: ParticipantSummaryType;
  }) => {
    const handleClick = () => {
      setSelectedList(selectedList.filter((select) => select !== participant));
    };
    return (
      <Container className={"participant-container"} onClick={handleClick}>
        <CheckBox isChecked={true} className={"patient-checkbox"} />
        <PersonProfile
          sex={participant.sex}
          carte={participant.carte}
          birth={participant.birth}
        />
      </Container>
    );
  };
  return (
    <div className="SelectedParticipantList">
      <Text className="selected-title" size={"h2"} color={"gray_700"}>
        選択中のリスト
      </Text>
      {selectedList.length < 1 ? (
        <Wall value="右側から患者を選択してください" icon={"select"} />
      ) : (
        <InternalScroll className="selected-internal-no-height">
          <div className={"selected-participant"}>
            {selectedList.map((participant, index) => (
              <SelectedParticipantUnit key={index} participant={participant} />
            ))}
          </div>
        </InternalScroll>
      )}
    </div>
  );
};

const SendLineDirectMessage = ({
  selectedList,
  isOpen,
  setIsOpen,
}: {
  selectedList: Array<ParticipantSummaryType>;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [message, setMessage] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    setIsOpenDialog(true);
  };
  const handleClickDialog = async () => {
    const response = await postSendLineDirectMessage(
      selectedList.map((data) => data.id),
      message,
    );
    !!response ? certification(response) : failure();
    setIsOpenDialog(false);
  };
  const certification = (data: Array<string>) => {
    setToast("メッセージを送信しました", true);
    setIsOpen(false);
    setIsOpenResult(true);
    setResultCarteIds(data);
    setIsLoading(false);
    setMessage("");
  };
  const failure = () => {
    setToast("メッセージの送信に失敗しました", false);
    setIsLoading(false);
  };
  // 改行のギミックの実装
  const textIndentation = (value: string) => {
    const splitText: Array<string> = value.split("\n");
    return (
      <>
        {splitText.map((text, index) => (
          <span key={index}>
            {index > 0 && <br />}
            {text}
          </span>
        ))}
      </>
    );
  };

  // リザルト表示
  const [isOpenResult, setIsOpenResult] = useState(false);
  const [resultCarteIds, setResultCarteIds] = useState<Array<string>>();

  return (
    <div className="SendLineDirectMessage">
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        {isLoading ? (
          <div
            style={{
              height: `calc(60vh)`,
            }}
          >
            <Center className="loader">
              <Loader size={30} />
            </Center>
          </div>
        ) : (
          <div className="line-dm-message">
            <Text size="h2" className="dm-title">
              LINEダイレクトメッセージを送信
            </Text>
            <Text size="lg" color={"gray_700"} className="dm-exp">
              送信されるメッセージを設定してください。
            </Text>
            <div className="line-simulate-box">
              <LineSimulator
                messages={[
                  [
                    message !== ""
                      ? message + "\n\n(※本メッセージは送信専用です)"
                      : "",
                  ],
                ]}
              />
              <TextArea
                value={message}
                className={"dm-textarea"}
                rows={15}
                handle={(value: string) => {
                  setMessage(value);
                }}
                placeholder="送信するメッセージを入力してください"
              />
            </div>
            <div className="dm-button">
              <TextButton
                value={
                  message
                    ? "メッセージを一斉送信する"
                    : "メッセージを入力してください"
                }
                onClick={handleClick}
                type={"black"}
                disable={!message}
              />
            </div>
          </div>
        )}
      </Modal>
      <ConfirmDialog
        title={"以下の内容でメッセージを送信します"}
        text={[
          `送信される人数：${selectedList.length}人`,
          "送信されるメッセージ：",
          textIndentation(message),
        ]}
        isOpen={isOpenDialog}
        setIsOpen={setIsOpenDialog}
        className={"line-dm-dialog"}
        handleClick={handleClickDialog}
      />
      <Dialog
        title={`メッセージが送信されました`}
        text={[
          `正常にメッセージが送信された対象者は以下${resultCarteIds?.length}名です`,
          <div>
            {resultCarteIds?.map((data, index) => (
              <span key={index}>
                {index !== 0 && <br />}
                {data}
              </span>
            ))}
          </div>,
        ]}
        isOpen={isOpenResult}
        setIsOpen={() => {
          setIsOpenResult(false);
        }}
        buttonValue={"閉じる"}
        handleClick={() => {
          setIsOpenResult(false);
        }}
      />
    </div>
  );
};
