import "./index.scss";

import { TableBody, TableHead } from "types/types";
import { Badge } from "ui/atoms/badge";
import { Person } from "ui/atoms/person";
import { circleButton, CircleButton } from "ui/molecules/circle-button";
import { TextCompresser } from "ui/atoms/text-compresser";
import { Wall } from "ui/molecules/wall";

export const TableData = ({
  tableHead,
  tableBody,
  answerData,
  handleClick,
  isAnonymous,
}: {
  tableHead: Array<TableHead>;
  tableBody?: Array<TableBody>;
  answerData?: Array<string | number | null | undefined>;
  handleClick?: (index: number) => void;
  isAnonymous: boolean;
}) => {
  return (
    <>
      <table className="TableData">
        {/* 長いときに横スクロールできるようにしたい */}
        <thead>
          <tr>
            {!!tableBody && (
              <>
                <th className="sex">性別</th>
                {!isAnonymous && <th className="carte">カルテID</th>}
              </>
            )}
            {tableHead.map((thead, index) => (
              <th key={index} className="content">
                <TextCompresser text={thead.text} />
              </th>
            ))}
            {!!handleClick && (
              <th className="edit">
                <Badge type="編集" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {/* アンケート毎の確認で用いるUI */}
          {tableBody?.map((data, index) => (
            <AnswerRaw
              thead={tableHead}
              sex={data.sex}
              carte={data.carte}
              answer={data.answer}
              index={index}
              key={index}
              onClick={!!handleClick ? () => handleClick(index) : undefined}
              isAnonymous={isAnonymous}
            />
          ))}
          {/* 患者単位の確認で用いるUI */}
          {!!answerData && (
            <AnswerRaw
              thead={tableHead}
              answer={answerData}
              index={0}
              key={0}
              onClick={!!handleClick ? () => handleClick(0) : undefined}
              isAnonymous={isAnonymous}
            />
          )}
        </tbody>
      </table>
      {tableBody?.length === 0 && (
        <Wall
          value="回答されたデータはまだありません"
          icon="null"
          className="table-no-data-wall"
        />
      )}
    </>
  );
};

const AnswerRaw = ({
  thead,
  sex,
  carte,
  answer,
  index,
  onClick,
  isAnonymous,
}: {
  thead: Array<TableHead>;
  sex?: number;
  carte?: string;
  answer: Array<string | number | null | undefined>;
  index: number;
  onClick?: () => void;
  isAnonymous: boolean;
}) => {
  let answers: JSX.Element[] = [];
  sex !== undefined &&
    answers.push(
      <td className="icon" key={"sex"}>
        {sex === 0 && <Person sex="male" />}
        {sex === 1 && <Person sex="female" />}
        {sex !== 0 && sex !== 1 && <Person />}
      </td>,
    );
  carte !== undefined &&
    !isAnonymous &&
    answers.push(<td key={"carte"}>{carte}</td>);
  answer.forEach((answer, index) => {
    if (typeof answer === "number") {
      if (thead[index].type === 0) {
        answers.push(<td key={index}>{thead[index].choice[answer - 1]}</td>);
      } else if (thead[index].type === 2) {
        answers.push(<td key={index}>{answer.toString()}</td>);
      }
    } else if (typeof answer === "string") {
      answers.push(<td key={index}>{answer}</td>);
    } else if (answer === undefined || answer === null) {
      answers.push(<td key={index}>-</td>);
    }
  });
  return (
    <tr key={index} className="AnswerRaw">
      {answers}
      {!!onClick && (
        <td>
          <CircleButton type={circleButton.edit} onClick={onClick} />
        </td>
      )}
    </tr>
  );
};
