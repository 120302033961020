import { client } from "data/axios";

export async function getLineReRegistrationQR(
  id: string,
  code: string,
  redirectUri: string
): Promise<boolean | undefined> {
  try {
    const url = `/line-re-registration-qr?id=${id}&code=${code}&liffRedirectUri=${redirectUri}`;
    const response = await client.get<Array<string>>(url);
    console.log(response);
    return true;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
