import { useEffect } from "react";

import "./index.scss";

import { Text } from "ui/atoms/text";
import QRCode from "qrcode.react";
import { Container } from "ui/atoms/container";
import { useTabContext } from "components/tab-provider";

export const RegistrationParticipant = () => {
  const url = process.env.REACT_APP_REGI_PARTICIPANT;
  const URL = url ? url : "https://linq.ibd.ai/ibdlinq/registration/doctor/";
  const { setHeaderContent } = useTabContext();
  useEffect(() => {
    document.title = "患者登録";
    setHeaderContent({
      mainHeader: "患者登録",
      subHeader: "",
    });
  }, []);
  return (
    <Container className="RegistrationParticipant">
      <QRCode value={URL} renderAs="svg" size={256} />
      <div className="textbox">
        <Text size="h3" color="gray_800">
          医師は、上のQRを自身の端末で読み取って下さい。
        </Text>
        <Text size="h4">
          QRを読み込むと、端末に患者さん用のQRコードが表示されるので、
          <br />
          端末画面を患者さんに提示してください。
        </Text>
      </div>
    </Container>
  );
};
