import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./index.scss";

import { Text } from "ui/atoms/text";
import { Wall } from "ui/molecules/wall";
import { LoadingPage } from "components/loading-page";
import { QuestionnaireTitle } from "ui/molecules/questionnaire-title";
import { TableData } from "components/table-data";
import { useTabContext } from "components/tab-provider";
import { QuestionnaireData } from "types/types";
import { getQuestionnaireRunningData } from "data/api/getQuestionnaireRunningData";
import { TextButton } from "ui/molecules/text-button";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { AnswerEditor } from "components/answer-editor";
import { useAuthContext } from "components/auth-provider";
import { useConfirmContext } from "components/confirm-dialog-provider";
import { CsvDownload } from "components/csv-download";

export const QuestionnaireFindingsRunnging = () => {
  const params = useParams();
  const { setHeaderContent } = useTabContext();
  const [questionnaireData, setQuestionnaireData] =
    useState<QuestionnaireData>();
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const { user } = useAuthContext();
  useEffect(() => {
    setHeaderContent({
      mainHeader: "回答中データリスト",
      subHeader: "回答未完了者の回答状況",
    });
    getList();
  }, []);
  const getList = async () => {
    setIsLoading(true);
    const response = await getQuestionnaireRunningData(`${params.id}`);
    setQuestionnaireData(response);
    setIsLoading(false);
    document.title = response?.name || "回答中リスト";
  };
  const [isOpen, setAnsIsOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number>(0);
  const handleClick = (index: number) => {
    setEditIndex(index);
    setAnsIsOpen(true);
  };
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  const handleClickCsv = (csvData: QuestionnaireData) => {
    setConfirmDialog({
      title:
        "現在のアンケートデータの内容をCSVでダウンロードしますがよろしいですか？",
      text: [
        "ダウンロードしたCSVファイルはダウンロードフォルダに保存されます。",
      ],
      handleClick: () => {
        CsvDownload(csvData, "回答中");
      },
    });
    setIsOpen(true);
  };

  return isLoading ? (
    <LoadingPage />
  ) : !!!questionnaireData ? (
    <Wall value="アンケートデータの読み込みに失敗しました" icon="fail" />
  ) : (
    <div className="QuestionnaireFindings">
      <QuestionnaireTitle
        name={questionnaireData.name}
        type={questionnaireData.type}
        anonymous={questionnaireData.anonymous}
      />
      <Text size="md" color="danger" className="answer-incomplete-alert">
        ⚠️ 回答未完了者リスト ⚠️
      </Text>
      <div className="detail">
        <Text size="md">
          回答未完了者数：{questionnaireData.tableBody.length}
        </Text>
        <TextButton value="回答済のリスト" onClick={() => navigation("../")} />
        <TextButton
          // CSVダウンロードボタン
          className="csv-button"
          value="CSVダウンロード"
          onClick={() => handleClickCsv(questionnaireData)}
        />
      </div>
      <InternalScroll height="calc(100% - 110px)">
        <TableData
          tableHead={questionnaireData.tableHead}
          tableBody={questionnaireData.tableBody}
          handleClick={user?.admin ? handleClick : undefined} // admin権限でのみ値の書き替え可
          isAnonymous={questionnaireData.anonymous === 1}
        />
      </InternalScroll>
      {!!questionnaireData &&
        questionnaireData.tableBody.length > 0 &&
        editIndex < questionnaireData.tableBody.length && (
          <AnswerEditor
            questionnaireId={parseInt(params.id ? params.id : "0")}
            tableHead={questionnaireData.tableHead}
            tableBody={questionnaireData.tableBody[editIndex]}
            isOpen={isOpen}
            setIsOpen={setAnsIsOpen}
            handleReload={getList}
          />
        )}
    </div>
  );
};
