import { ReactNode } from "react";
import classNames from "classnames";

import "./index.scss";

export const Text = ({
  children,
  size = "sm",
  color = "black",
  className,
}: {
  children?: ReactNode;
  size?: TextSize;
  color?: TextColor;
  className?: string;
}) => {
  const classes = classNames("Text", size, color, className && className);
  return <span className={classes}>{children}</span>;
};

type TextSize = "h1" | "h2" | "h3" | "h4" | "h5" | "xs" | "sm" | "md" | "lg";
type TextColor =
  | "black"
  | "gray_400"
  | "gray_600"
  | "gray_700"
  | "gray_800"
  | "danger";
