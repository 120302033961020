import { AnswerEditor } from "components/answer-editor";
import { LoadingPage } from "components/loading-page";
import { useTabContext } from "components/tab-provider";
import { TableData } from "components/table-data";
import { getParticipant, ParticipantType } from "data/api/getParticipant";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TableBody, TableHead } from "types/types";
import { Container } from "ui/atoms/container";
import { PersonProfile } from "ui/molecules/person-profile";
import { QuestionnaireTitle } from "ui/molecules/questionnaire-title";
import { setToast } from "ui/molecules/toast";
import { Wall } from "ui/molecules/wall";
import "./index.scss";
import { iconButton, IconButton } from "ui/molecules/icon-button";
import { InternalScroll } from "ui/atoms/internal-scroll";
import { Text } from "ui/atoms/text";
import { useAuthContext } from "components/auth-provider";
import { TextButton } from "ui/molecules/text-button";
import { QuoCardHistoryListRegister } from "components/quocard-status-dialog";
import { getQuoHistoryList } from "data/api/getQuoHistory";
import { postQuoHistoryYearOverride } from "data/api/postQuoHistoryYearOverride";
import {
  QuocardStatusListType,
  NewQuocardStatusType,
} from "types/quocard-status";

export const ParticipantData = () => {
  const param = useParams();
  const { setHeaderContent } = useTabContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigate();
  const [participantData, setParticipantData] = useState<ParticipantType>();
  const handleClickHeader = () => {
    navigation("/participant");
  };
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  // QUOカード履歴(初期値は空)
  const [quoHistoryData, setQuoHistoryData] = useState<QuocardStatusListType[]>(
    [],
  );
  // QUOカード登録ダイアログ処理
  const handleClickRegister = () => {
    setIsOpenEditor(true);
  };

  useEffect(() => {
    document.title = "患者データ";
    setHeaderContent({
      mainHeader: "患者データ",
      subHeader: "",
      onClick: handleClickHeader,
    });
    (async () => {
      const response = await getParticipant(param.id ? param.id : "");
      response !== undefined
        ? setParticipantData(response)
        : setToast("患者データの取得に失敗しました", false);
      setIsLoading(false);
    })();
  }, []);

  const [isOpenAnswerEditor, setIsOpenAnswerEditor] = useState(false);
  const [answerEditor, setAnswerEditor] = useState<AnswerEditorType>();

  // 再レンダリング処理
  useEffect(() => {
    if (quoHistoryData) {
      setQuoHistoryData(quoHistoryData);
      return;
    }
  });

  // データ変更時の再レンダリング
  useEffect(() => {
    setQuoHistoryData(quoHistoryData);
    return;
  }, [quoHistoryData]);

  // Quoカード履歴情報の各処理
  // 年度登録
  const insertQuoYear = (patientId: string) => {
    (async () => {
      // 年度特定
      const today = new Date();
      const month = today.getMonth() + 1;
      let year = today.getFullYear();
      // 3月以前は現在年-1、4月以降は現在年を年度とする
      if (month <= 3) {
        year = year - 1;
      }
      const data: NewQuocardStatusType = {
        patientId: patientId,
        quoYear: year,
      };
      const response = await postQuoHistoryYearOverride(data);
      if (response == undefined || !response) {
        setToast("QUOカード提供履歴情報の年度更新に失敗しました", false);
      }
      // 登録後の一旦取得(行わないと再描画が行われないため)
      getQuoHistoryListData(patientId);
    })();
  };

  // Quoカード履歴一覧情報取得処理
  const getQuoHistoryListData = (patientId: string) => {
    (async () => {
      const response = await getQuoHistoryList(patientId);
      response !== undefined
        ? setQuoHistoryData(response)
        : setToast("QUOカード提供履歴情報の取得に失敗しました", false);
    })();
  };

  return isLoading ? (
    <LoadingPage />
  ) : !!!participantData ? (
    <Wall value="患者データを取得できませんでした" icon="fail" />
  ) : (
    <>
      <Container className="ParticipantData">
        <IconButton
          value={iconButton.arrowLeft}
          onClick={() => navigation("/participant")}
          className={"back-button"}
        />
        <PersonProfile
          sex={participantData.sex}
          carte={participantData.carte}
          birth={participantData.birth}
        />
        {participantData?.datas.length > 0 ? (
          participantData?.datas.map((data, index) => (
            <TableDataBox
              key={index}
              questionnaireId={data.id}
              name={data.name}
              type={data.type}
              anonymous={data.anonymous}
              tableHead={data.tableHead}
              tableBody={{
                id: participantData.id,
                sex: participantData.sex,
                carte: participantData.carte,
                answer: data.tableBody,
              }}
              setAnswerEditor={setAnswerEditor}
              setIsOpen={setIsOpenAnswerEditor}
              admin={user?.admin}
            />
          ))
        ) : (
          <div className="no-answer-data">
            <Text size="h3" color={"gray_600"}>
              回答されたアンケートデータはありません
            </Text>
          </div>
        )}
        <TextButton
          className="quoHistoryButton"
          value="QUOカード提供履歴"
          icon={undefined}
          onClick={async () => {
            // 年度更新
            insertQuoYear(participantData.id);
            // 一覧情報取得
            getQuoHistoryListData(participantData.id);
            // ダイアログの表示設定
            setTimeout(() => {
              handleClickRegister();
            }, 1000);
          }}
        />
      </Container>
      <QuoCardHistoryListRegister
        patientId={participantData.id}
        isOpen={isOpenEditor}
        setIsOpen={setIsOpenEditor}
        quoHistoryData={quoHistoryData}
        setQuoHistoryData={setQuoHistoryData}
      />
      {!!answerEditor && (
        <AnswerEditor
          questionnaireId={answerEditor.questionnaireId}
          tableHead={answerEditor.tableHead}
          tableBody={{
            id: answerEditor.tableBody.id,
            sex: answerEditor.tableBody.sex,
            carte: answerEditor.tableBody.carte,
            answer: answerEditor.tableBody.answer,
          }}
          isOpen={isOpenAnswerEditor}
          setIsOpen={setIsOpenAnswerEditor}
          handleReload={async () => {
            const response = await getParticipant(param.id ? param.id : "");
            !!response && setParticipantData(response);
          }}
        />
      )}
    </>
  );
};

type AnswerEditorType = {
  questionnaireId: number;
  tableHead: Array<TableHead>;
  tableBody: TableBody;
};

const TableDataBox = ({
  questionnaireId,
  name,
  type,
  anonymous,
  tableHead,
  tableBody,
  setAnswerEditor,
  setIsOpen,
  admin,
}: {
  questionnaireId: number;
  name: string;
  type: number;
  anonymous: number;
  tableHead: Array<TableHead>;
  tableBody: TableBody;
  setAnswerEditor: React.Dispatch<
    React.SetStateAction<AnswerEditorType | undefined>
  >;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  admin: boolean | undefined;
}) => {
  return (
    <>
      <Container className="tabledata-box">
        <QuestionnaireTitle name={name} type={type} anonymous={anonymous} />
        <InternalScroll>
          <TableData
            tableHead={tableHead}
            answerData={tableBody.answer}
            handleClick={
              admin
                ? () => {
                    setAnswerEditor({
                      questionnaireId,
                      tableHead,
                      tableBody,
                    });
                    setIsOpen(true);
                  }
                : undefined
            }
            isAnonymous={anonymous === 1}
          />
        </InternalScroll>
      </Container>
    </>
  );
};
