import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FileText,
  PenTool,
  Package,
  Search,
  UserPlus,
  Users,
  ChevronsLeft,
  File,
} from "react-feather";

import "./index.scss";
import Logo from "assets/image/logo.svg";

import { Text } from "ui/atoms/text";
import { Badge } from "ui/atoms/badge";
import { useAuthContext } from "components/auth-provider";
import { useTabContext } from "components/tab-provider";
import classNames from "classnames";
import { useLayoutContext } from "..";
import { SideBar } from "ui/molecules/side-bar";
import { useConfirmContext } from "components/confirm-dialog-provider";

export const Sidebar = () => {
  const auth = useAuthContext();
  const layout = useLayoutContext();
  const handleClick = () => {
    layout.setSidebar(false);
  };
  return (
    <SideBar isActive={layout.sidebar}>
      <div className="chevrons">
        <ChevronsLeft onClick={handleClick} />
      </div>
      <SideHeader user={auth.user?.user} />
      <SideMenu isAdminUser={auth.user?.admin} />
    </SideBar>
  );
};

const SideHeader = ({ user }: { user?: string }) => {
  const mode = process.env.REACT_APP_MODE;
  return (
    <div className="SideHeader">
      <div className="logo">
        <img src={Logo} alt="IBD-LINQ" />
        {mode !== "prd" && !!mode && <Badge type={mode} />}
      </div>
      <Text size="h5">
        東北大学病院LINEアンケート
        <br />
        管理画面
      </Text>
      <Text size="xs" color="gray_800" className="user">
        {user}
      </Text>
    </div>
  );
};

const SideMenu = ({ isAdminUser }: { isAdminUser?: boolean }) => {
  const { header } = useTabContext();
  return (
    <div className="SideMenu">
      <SideMenuContent
        icon={<FileText />}
        title="アンケート一覧"
        path="questionnaire-list"
        active={header?.mainHeader === "アンケート一覧" ? true : false}
      />
      {isAdminUser && (
        <SideMenuContent
          icon={<PenTool />}
          title="新規アンケート作成"
          path="questionnaire-build"
          active={header?.mainHeader === "新規アンケート作成" ? true : false}
        />
      )}
      {isAdminUser && (
        <SideMenuContent
          icon={<Package />}
          title="アンケート管理"
          path="questionnaire-bundle"
          active={header?.mainHeader === "アンケート管理" ? true : false}
        />
      )}
      {isAdminUser && (
        <SideMenuContent
          icon={<File />}
          title="患者グループ"
          path="patient-group-list"
          active={header?.mainHeader === "患者グループ" ? true : false}
        />
      )}
      <SideMenuContent
        icon={<Search />}
        title="患者データ"
        path="participant"
        active={header?.mainHeader === "患者データ" ? true : false}
      />
      <SideMenuContent
        icon={<UserPlus />}
        title="患者登録"
        path="registration-participant"
        active={header?.mainHeader === "患者登録" ? true : false}
      />
      {isAdminUser && (
        <SideMenuContent
          icon={<Users />}
          title="医師登録"
          path="registration-doctor"
          active={header?.mainHeader === "医師登録" ? true : false}
        />
      )}
    </div>
  );
};

const SideMenuContent = ({
  icon,
  title,
  path,
  active = false,
}: {
  icon: ReactNode;
  title: string;
  path: string;
  active: boolean;
}) => {
  const navigation = useNavigate();

  // アンケートビルドページの場合のみアラート
  const location = useLocation();
  const { setConfirmDialog, setIsOpen } = useConfirmContext();
  const alertTransitionFromBuild = (onClick: () => void) => {
    setConfirmDialog({
      title: "ページを移動してよろしいですか？",
      text: ["編集した内容は保存されません"],
      handleClick: onClick,
    });
    setIsOpen(true);
  };
  const handleClick = () => {
    location.pathname !== "/questionnaire-build" &&
    location.pathname.indexOf("/questionnaire-bundle/") === -1
      ? navigation(`/${path}`)
      : alertTransitionFromBuild(() => {
          navigation(`/${path}`);
        });
  };
  const classes = classNames("SideMenuContent", active && "active");
  return (
    <div className={classes} onClick={handleClick}>
      <div className="context">
        <div className="icon">{icon}</div>
        <Text size="lg">{title}</Text>
      </div>
      <div className="background"></div>
    </div>
  );
};
