import classNames from "classnames";
import { ReactNode } from "react";
import { Text } from "ui/atoms/text";
import "./index.scss";

export const BuildBlock = ({
  title,
  exp,
  children,
  className,
  draggable = false,
}: {
  title: string;
  exp?: string;
  children: ReactNode;
  className?: string;
  draggable?: boolean;
}) => {
  const classes = classNames(["BuildBlock", className]);
  return (
    <div className={classes} draggable={draggable}>
      <Text size={"h4"} className={"build-block-title"}>
        {title}
      </Text>
      {!!exp && (
        <Text size={"xs"} className={"build-block-exp"}>
          {exp}
        </Text>
      )}
      {children}
    </div>
  );
};
