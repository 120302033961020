import "./index.scss";

export const Spinner = () => {
  return (
    <div className="Spinner">
      <Spin />
    </div>
  );
};

const Spin = () => {
  return (
    <div className="Spin">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.08 22C6.48 22 2 17.52 2 12.08C2 6.64 6.48 2 12.08 2C17.68 2 22.16 6.48 22.16 12.08C22.16 13.04 21.52 13.68 20.56 13.68C19.6 13.68 18.96 13.04 18.96 12.08C18.8 8.24 15.76 5.2 12.08 5.2C8.24 5.2 5.2 8.24 5.2 12.08C5.2 15.92 8.24 18.96 12.08 18.96C13.04 18.96 13.68 19.6 13.68 20.56C13.68 21.52 12.88 22 12.08 22Z"
          fill="#DDDDDD"
        />
        <path
          d="M17.9984 19.5995C17.5184 19.5995 17.1984 19.4395 16.8784 19.1195C16.2384 18.4795 16.2384 17.5195 16.8784 16.8795C18.1584 15.5995 18.9584 13.8395 18.9584 12.0795C18.9584 11.1195 19.5984 10.4795 20.5584 10.4795C21.5184 10.4795 22.1584 11.1195 22.1584 12.0795C22.1584 14.7995 21.0384 17.3595 19.2784 19.1195C18.7984 19.4395 18.4784 19.5995 17.9984 19.5995Z"
          fill="#AFAFAF"
        />
        <path
          d="M12.08 22C6.48 22 2 17.52 2 12.08C2 6.64 6.48 2 12.08 2C17.68 2 22.16 6.48 22.16 12.08C22.16 13.04 21.52 13.68 20.56 13.68C19.6 13.68 18.96 13.04 18.96 12.08C18.8 8.24 15.76 5.2 12.08 5.2C8.24 5.2 5.2 8.24 5.2 12.08C5.2 15.92 8.24 18.96 12.08 18.96C13.04 18.96 13.68 19.6 13.68 20.56C13.68 21.52 12.88 22 12.08 22Z"
          fill="#DDDDDD"
        />
        <path
          d="M18.8013 18.6395C18.4813 18.6395 18.1613 18.4795 17.8413 18.3195C17.2013 17.6795 17.0413 16.7195 17.5213 16.0795C18.3213 14.9595 18.8013 13.5195 18.8013 12.0795C18.8013 11.1195 19.4413 10.4795 20.4013 10.4795C21.3613 10.4795 22.0013 11.1195 22.0013 12.0795C22.0013 14.1595 21.3613 16.2395 20.0813 17.9995C19.9213 18.3195 19.4413 18.6395 18.8013 18.6395Z"
          fill="#AFAFAF"
        />
      </svg>
    </div>
  );
};
