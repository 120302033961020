import { Routes, Route } from "react-router-dom";

import { Layout } from "components/layout";
import { useAuthContext } from "components/auth-provider";
import { Error404 } from "pages/error-404";
import { Home } from "pages/home";
import { QuestionnaireFindings } from "pages/questionnaire-findings";
import { QuestionnaireList } from "pages/questionnaire-list";
import { QuestionnaireBuild } from "pages/questionnaire-build";
import { QuestionnaireBundle } from "pages/questionnaire-bundle";
import { RegistrationParticipant } from "pages/registration-participant";
import { Participant } from "pages/participant";
import { RegistrationDoctor } from "pages/registration-doctor";
import { SignIn } from "pages/signin";
import { SignUp } from "pages/signup";
import { QuestionnaireBundleEdit } from "pages/questionnaire-bundle/edit";
import { QuestionnaireFindingsRunnging } from "pages/questionnaire-findings/running";
import { ParticipantData } from "pages/participant-data";
import { ParticipantDataLineRegistration } from "pages/participant-data-line-registration";
import { LineReRegistrationQR } from "pages/line-re-registration-qr";
import { PatientGroupList } from "pages/patient-group-list";

// ReactRouter v6 を採用しています
// 以下の記述に従ってルーティングを記述してください
// ↓↓↓参考↓↓↓
// https://ralacode.com/blog/post/how-to-use-react-router/

export const RouteSelector = () => {
  const auth = useAuthContext();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path="questionnaire-list" element={<QuestionnaireList />} />
        <Route path="questionnaire-findings/:id">
          <Route path="" element={<QuestionnaireFindings />} />
          <Route path="running" element={<QuestionnaireFindingsRunnging />} />
        </Route>
        {auth.user?.admin && (
          <Route path="questionnaire-build" element={<QuestionnaireBuild />} />
        )}
        {auth.user?.admin && (
          <Route path="questionnaire-bundle">
            <Route path="" element={<QuestionnaireBundle />} />
            <Route path=":id" element={<QuestionnaireBundleEdit />} />
            {/* <Route path="implement">
              <Route path="" element={<QuestionnaireBundleImplement />} />
            </Route>
            <Route path="unimplement">
              <Route path="" element={<QuestionnaireBundleUnImplement />} />
              <Route path=":id" element={<QuestionnaireEdit />} />
            </Route> */}
          </Route>
        )}
        <Route path="participant">
          <Route path="" element={<Participant />} />
          <Route path=":id" element={<ParticipantData />} />
          <Route path="line-registration">
            <Route path=":id" element={<ParticipantDataLineRegistration />} />
          </Route>
        </Route>
        <Route
          path="registration-participant"
          element={<RegistrationParticipant />}
        />
        {auth.user?.admin && (
          <Route path="registration-doctor" element={<RegistrationDoctor />} />
        )}
        <Route path="/patient-group-list" element={<PatientGroupList />} />
        <Route path="*" element={<Error404 />} />
      </Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup/:email" element={<SignUp />} />
      <Route
        path="/line-re-registration-qr"
        element={<LineReRegistrationQR />}
      />
    </Routes>
  );
};
