import React, { ReactNode, useContext, useState } from "react";

import { HeaderContent } from "components/layout/header";

export type TabContext = {
  header?: HeaderContent;
  setHeaderContent: (header: HeaderContent) => void;
};

const TabContext = React.createContext<TabContext>({
  setHeaderContent: () => {
    console.log("tab-provider unimplement.");
  },
});

export const TabContextProvider = ({ children }: { children: ReactNode }) => {
  const [header, setHeader] = useState<HeaderContent>();
  const setHeaderContent = (headerContent: HeaderContent) => {
    setHeader(headerContent);
  };
  return (
    <TabContext.Provider value={{ header, setHeaderContent }}>
      {children}
    </TabContext.Provider>
  );
};

export const useTabContext = (): TabContext => {
  return useContext(TabContext);
};
