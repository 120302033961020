import { client } from "data/axios";
import { DoctorAccountIssuance } from "types/types";

export async function postAccountIssuance(
  email: string,
  url: string,
): Promise<DoctorAccountIssuance | undefined> {
  const data = { email: email, url: url };
  try {
    const url = "/account-issuance";
    const response = await client.post(url, data);
    return response.data;
    
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
