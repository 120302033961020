import { client } from "data/axios";

export type LineToken = {
  id: string;
  carte: string;
  tokenCode: string;
  sysURL: string;
  clientId: string;
};

export async function postGenerateLineToken(
  carte: string
): Promise<LineToken | undefined> {
  const data = { carte: carte };
  try {
    const url = "/generate-line-token";
    const response = await client.post(url, data);

    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
