import "./index.scss";
import LineIcon from "assets/image/line-icon.jpg";
import { ReactNode, useEffect, useState } from "react";
import { Center } from "ui/atoms/center";
import { Loader } from "react-feather";
import classNames from "classnames";

export type PostbackProps = {
  title: string;
  exp: string;
  choices: Array<string>;
};

export const LineSimulator = ({
  messages,
}: {
  messages: Array<Array<string> | PostbackProps>;
}) => {
  // レンダリング時にクルクル画面を一秒間だけ表示させる
  const [renderes, setRenderes] = useState<Array<number>>([]);
  useEffect(() => {
    setRenderes([0, ...renderes]);
  }, [messages]);

  return (
    <>
      <div className="LineSimulator">
        {/* <<!-- ▼会話エリア scrollを外すと高さ固定解除 --> */}
        <div className="line__contents scroll">
          {messages.map((message, index) => (
            <div key={index} className="line__left">
              <figure>
                <img src={LineIcon} alt={"icon"} />
              </figure>
              {Array.isArray(message) ? (
                message.map((text, index) => (
                  <LineTextMessage key={index} index={index} message={text} />
                ))
              ) : (
                <LinePostbackMessage postback={message} />
              )}
            </div>
          ))}
        </div>
        {renderes.map((_, index) => (
          <LineSimulatorRendering key={index} />
        ))}
      </div>
    </>
  );
};

const LineTextMessage = ({
  index,
  message,
}: {
  index: number;
  message: string;
}) => {
  const classes = classNames(["message", index === 0 && "first"]);
  useEffect(() => {}, [message]);
  const [text, setText] = useState<ReactNode>();
  useEffect(() => {
    const splitMessage: Array<string> = message.split("\n");
    setText(
      splitMessage.map((message, index) => (
        <span key={index}>
          {index !== 0 && <br />}
          {message !== ""
            ? !message.match(/^[\s]*$/)
              ? message
              : message.replace(/ /g, "　")
            : "　"}
        </span>
      )),
    );
  }, [message]);
  return (
    <div className="line__left-message">
      <div className={classes}>
        {!!message ? text : <span className="line-message-placeholder">-</span>}
      </div>
    </div>
  );
};

const LinePostbackMessage = ({ postback }: { postback: PostbackProps }) => {
  return (
    <div className="line__left-message">
      <div className="message postback">
        <>
          <div className="postback-title">{postback.title}</div>
          <div className="postback-exp">{postback.exp}</div>
          <div className="postback-bar" />
          {postback.choices.map((choice, index) => (
            <div key={index} className="postback-choice">
              {choice}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

const LineSimulatorRendering = () => {
  const [isDisable, setIsDesable] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsDesable(true);
    }, 500);
  }, []);
  return isDisable ? (
    <></>
  ) : (
    <div className="LineSimulatorRendering">
      <Center className="l-s-center">
        <Loader size={30} color={"white"} />
      </Center>
    </div>
  );
};
